/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useLazyGetOrganizationListQuery } from "../../../services/cms";
import { SetStateAction, useEffect, useState } from "react";
import { Organizations } from "../../../types/General";
import NoDataFound from "../../../components/noData";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validation";
import { becomePartnerLink } from "../../../helpers/constants/urls";

const HomePartner = ({ type }: { type: string }) => {
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [getPartnersMethod] = useLazyGetOrganizationListQuery();

  const [partnersList, setPartnersList] = useState<Organizations[]>([]);

  const getAllPartnersList = async () => {
    try {
      const res = await getPartnersMethod({
        b2bType: 2,
        page: 1,
        size: 10,
        pagination: true,
        search: debouncedSearchTerm ? debouncedSearchTerm.trim() : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setPartnersList(res?.data?.organisation || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPartnersList();
  }, [debouncedSearchTerm]);

  return (
    <>
      {type ? (
        <section className="home_partner_sc bg_primary u_spc">
          <div className="">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => {
                setSearchTerm("");
                setDebouncedSearchTerm("");
              }}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </div>
        </section>
      ) : undefined}
      {partnersList?.length ? (
        <section className="home_partner_sc bg_primary u_spc">
          <div className="conta_iner">
            {partnersList?.length ? (
              <div className="s_head text_white text_center">
                <h2>Our Partners</h2>
              </div>
            ) : undefined}

            <Swiper
              spaceBetween={30}
              slidesPerView={3}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              // centeredSlides
              loop={true}
              navigation
              breakpoints={{
                300: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                  centeredSlides: false,
                },
                576: {
                  slidesPerView: 2,
                  centeredSlides: false,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="partner_listing inline_arrow"
            >
              {partnersList?.length
                ? partnersList?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div
                        onClick={() =>
                          navigate(`/marketplace-vendors/${item?._id}`, {
                            state: "partner",
                          })
                        }
                        className="partner_single"
                      >
                        <figure>
                          <img
                            src={
                              item?.image || "/images/partner_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                      </div>
                    </SwiperSlide>
                  ))
                : undefined}
            </Swiper>
            <div className="btn_flex">
              {partnersList?.length ? (
                <button
                  onClick={() =>
                    navigate("/marketplace-vendors", { state: "partner" })
                  }
                  className="btn btn_lg btn_white br"
                >
                  See more
                </button>
              ) : undefined}
              <button
                // onClick={() => navigate("/become-partner")}
                onClick={() => {
                  if (window) {
                    window.open(becomePartnerLink, "_self");
                  }
                }}
                className="btn btn_lg btn_white br"
              >
                Become Partner
              </button>
            </div>
          </div>
        </section>
      ) : type ? (
        <section className="home_partner_sc bg_primary u_spc">
          <NoDataFound image={"/images/noData.png"} text="No Data Found" />
        </section>
      ) : undefined}
    </>
  );
};

export default HomePartner;
