/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";

import { Blog } from "../../../types/General";
import { useLazyGetAllTestimonialQuery } from "../../../services/cms";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@mui/material";

const HomeTestimonial = () => {
  const [getTestimonial] = useLazyGetAllTestimonialQuery();

  const isMobile = useMediaQuery("(max-width:600px)");

  const [activeIndex, setActiveIndex] = useState(0);
  const [testimonial, setTestimonial] = useState<Blog[]>([]);

  const [nav1, setNav1] = useState<any | null>(null);
  const [nav2, setNav2] = useState<any | null>(null);
  const sliderRef1 = useRef<any | null>(null);
  const sliderRef2 = useRef<any | null>(null);

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const getAllTestimonial = async () => {
    try {
      const res = await getTestimonial({}).unwrap();
      if (res?.statusCode === 200) {
        setTestimonial(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTestimonial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAfterChange = (index: number) => {
    setActiveIndex(index);
    nav2.slickGoTo(index);
  };

  // const settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   centerPadding: "60px",
  //   slidesToShow: 3,
  //   speed: 500,
  // };

  return (
    <section className="home_testimonial_sc ub_spc">
      <div className="conta_iner">
        {testimonial?.length ? (
          <div className="s_head text_center">
            <h2>Testimonials</h2>
          </div>
        ) : undefined}
        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2.current = slider)}
          slidesToShow={
            isMobile
              ? 1
              : testimonial?.length > 6
              ? 5
              : testimonial?.length % 2 === 0
              ? testimonial?.length - 1
              : testimonial?.length
          }
          swipeToSlide={testimonial?.length > 1}
          centerPadding="0px"
          focusOnSelect={testimonial?.length > 1}
          centerMode={true}
          infinite={testimonial?.length > 1}
          slidesToScroll={1}
          arrows={ false}
          // autoplay
        >
          {testimonial?.length
            ? [...testimonial, ...testimonial]?.map((item, index) => (
                <div
                  className={`thumb_single ${
                    index === activeIndex ? "active_slide" : ""
                  }`}
                  key={index}
                >
                  <figure>
                    <img
                      src={
                        item?.image || "/images/testimonial_attachment_1.jpg"
                      }
                      alt=""
                    />
                  </figure>
                </div>
              ))
            : undefined}
        </Slider>
        <Slider
          afterChange={handleAfterChange}
          asNavFor={nav2}
          ref={(slider) => (sliderRef1.current = slider)}
          infinite={testimonial?.length > 1}
          slidesToShow={1}
          slidesToScroll={1}
          
          arrows={isMobile ? true : false}
        >
          {testimonial?.length
            ? [...testimonial, ...testimonial]?.map((item, index) => (
                <div key={index}>
                  <div className="gallery_head hd_5">
                    <h3>{item?.title || ""}</h3>
                    <p>{item?.author || ""}</p>
                  </div>
                  <hr className="gallery_hr" />
                  <div className="gallery_single">
                    <img src="/images/quote_icon_1.svg" alt="Icon" />
                    {/* <p>{item?.description || ""}</p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.description || "",
                      }}
                    ></p>

                    <img src="/images/quote_icon_2.svg" alt="Icon" />
                  </div>
                </div>
              ))
            : undefined}
        </Slider>
      </div>
    </section>
  );
};

export default HomeTestimonial;
