const NewsBanner = () => {

    return (
        <section className="newsBanner_sc">
            <div className="conta_iner">
                <div className="s_hd hd_2 text_white">
                    <h1>Bantgo-news</h1>
                </div>
            </div>
        </section>
    );
};

export default NewsBanner;
