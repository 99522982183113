/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment from "moment";
import { Blog } from "../../../types/General";
import SitePagination from "../../../components/sitePagination";
import { useLazyGetAllBlogQuery } from "../../../services/cms";
import { useEffect, useState } from "react";
import { Loader } from "../../../helpers";

const BlogsListing = () => {
  const navigate = useNavigate();
  const [getBlog, { isLoading }] = useLazyGetAllBlogQuery();

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllBlogs = async () => {
    try {
      const res = await getBlog({
        page: page,
        size: 12,
      }).unwrap();
      if (res?.statusCode === 200) {
        setBlogs(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, [page]);

  return (
    <section className="home_blogs_sc ub_spc">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="s_head text_center">
          <h2>Blogs</h2>
        </div>
        <div className="blog_listing">
          <div className="gap_m hd_6">
            {blogs?.length
              ? blogs?.map((item) => (
                  <div
                    className="blog_single"
                    onClick={() => navigate(`detail/${item?._id}`)}
                  >
                    <figure>
                      <img src={item?.image || ""} alt="Image" />
                    </figure>
                    <span className="tag">Blog</span>
                    <h3>{item?.title || ""}</h3>
                    <p>{moment(item?.createdAt).format("MMM D, YYYY")}</p>
                  </div>
                ))
              : null}
          </div>
          {totalPages > 1 ? (
            <SitePagination
              module={blogs}
              page={page}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          ) : undefined}
          {!isLoading && !blogs?.length ? (
            <div className="no_date_sc hd_4">
              <figure>
                <img src="/images/noData.png" alt="noData" />
              </figure>
              <h2>No Data Found</h2>

              <a href="javascript:void(0)" className="btn btn_primary">
                Back to Home
              </a>
            </div>
          ) : undefined}
        </div>
      </div>
    </section>
  );
};

export default BlogsListing;
