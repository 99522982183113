import Layout from "../../layout";
import FAQContent from "./features/faqContent";
import "./faq.scss";

const FAQ = () => {
  return (
    <Layout>
      <main className="page_faq">
        <FAQContent />
      </main>
    </Layout>
  );
};

export default FAQ;
