/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import {
  useAddSocialMediaMutation,
  useUpdateSocialMediaMutation,
} from "../../../services/main/setting";
import { Loader, errorToast, successToast } from "../../../helpers";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isString } from "../../../utils/validation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

type ManageProps = {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
  setSocialMediaHandles: Dispatch<SetStateAction<number>>;
  getSocialMediaData: () => void;
  socialMediaHandles: any;
  selectedMedia: any;
};

const AddSocialMedia = ({
  steps,
  setSteps,
  setSocialMediaHandles,
  getSocialMediaData,
  socialMediaHandles,
  selectedMedia,
}: ManageProps) => {
  const [socialAccount, setSocialAccount] = useState("default");
  const [error, setError] = useState<boolean>(false);

  const [addSocialMediaMethod] = useAddSocialMediaMutation();
  const [updateSocialMediaMethod] = useUpdateSocialMediaMutation();
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setSocialAccount(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      socialId: selectedMedia?.socialMediaId
        ? selectedMedia?.socialMediaId
        : "",
      socialDescription: selectedMedia?.description
        ? selectedMedia?.description
        : "",
      phone: selectedMedia?.phone ? selectedMedia?.phone : "",
      countryCode: selectedMedia?.countryCode
        ? selectedMedia?.countryCode
        : "+971",
    },
    validationSchema: Yup.object({
      socialDescription: Yup.string()
        // .required("This field is required")
        .max(80, "Maximum 80 character are allowed"),
      // .min(2, "Minimum 5 characters are required"),

      socialId: Yup.string()
        .required("Social Media Id is required")
        .min(6, "Social Media Id must be at least 6 characters")
        .matches(/^[^@]*$/, "Social Media Id cannot contain '@'"),
      phone: Yup.string()
        // .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      setError(true);

      if (socialAccount === "default") {
        return;
      }
      setError(false);
      try {
        let data = {
          // countryCode: formik.values.countryCode,
          // phone: formik.values.phone,
          socialMediaType: socialAccount || 1,
          socialMediaId: formik.values.socialId,
          // description: formik.values.socialDescription,
        } as any;

        if (formik.values.socialDescription) {
          data.description = formik.values.socialDescription;
        }

        if (formik.values.phone) {
          data.phone = formik.values.phone;
          data.countryCode = formik.values.countryCode;
        }

        const body = generateEncryptedKeyBody(data) as CommonBody;
        setLoading(true);
        let res =
          socialMediaHandles !== 2
            ? await updateSocialMediaMethod({
                body,
                socialMediaId: socialMediaHandles,
              }).unwrap()
            : await addSocialMediaMethod(body).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setSocialMediaHandles(1);
          successToast(
            socialMediaHandles !== 2
              ? "Social Media updated successfully"
              : "Social Media added successfully"
          );
          getSocialMediaData();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        setLoading(false);
      }
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  useEffect(() => {
    if (selectedMedia?.socialMediaType) {
      setSocialAccount(selectedMedia?.socialMediaType);
    }
  }, [selectedMedia]);

  return (
    <>
      <Loader isLoad={loading} />
      <div className="head_flex">
        <h3>
          {socialMediaHandles !== 2 ? "Edit Social Media" : "Add Social Media"}
        </h3>
        <button
          onClick={() => setSocialMediaHandles(1)}
          className="btn btn_primary"
        >
          Back
        </button>
      </div>
      <form action="" className="form" onSubmit={formik.handleSubmit}>
        <div className="form_control">
          <label>SELECT SOCIAL MEDIA PLATFORM *</label>
          <Select
            fullWidth
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            label="social-account"
            value={socialAccount}
            onChange={handleChange}
          >
            <MenuItem disabled value="default">
              Name
            </MenuItem>
            {/* <MenuItem value={1}>Facebook</MenuItem> */}
            <MenuItem value={2}>Telegram</MenuItem>
            {/* <MenuItem value={3}>Twitter</MenuItem>
            <MenuItem value={4}>Linkedin</MenuItem> */}
          </Select>
          {error && socialAccount === "default" ? (
            <h6 className="err_msg" style={{ paddingTop: 10 }}>
              This field is required
            </h6>
          ) : (
            ""
          )}
        </div>
        <div className="form_control">
          <label>SOCIAL ID *</label>
          <TextField
            hiddenLabel
            placeholder="Enter Social Media Id"
            fullWidth
            type="text"
            variant="outlined"
            name="socialId"
            className="text_field"
            id="socialId"
            onChange={(val) => {
              console.log(val.target.value, "val.target.value");

              if (
                val.target.value === " " ||
                val.target.value === "." ||
                val.target.value?.includes("@")
              ) {
              } else {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.socialId}
            // helperText={formik.touched.socialId && formik.errors.socialId}
          />
          {Boolean(formik.touched.socialId && formik.errors.socialId) ? (
            <span className="helper-text">
              <h6 className="err_msg">
                {(formik.touched.socialId && formik.errors.socialId) as any}
              </h6>
            </span>
          ) : undefined}
        </div>
        <div className="form_control">
          <label>NUMBER LINKED WITH ACCOUNT</label>
          {/* <TextField
            hiddenLabel
            placeholder="Enter Linked Number"
            fullWidth
        name="name"
            type="text"
            variant="outlined"
          /> */}
          <PhoneInput
            value={formik.values.countryCode + formik.values.phone}
            country={"us"}
            placeholder="Enter Linked Number"
            enableSearch={true}
            inputStyle={{ width: "100%" }}
            onChange={(phone, country) => handleChangePhone(phone, country)}
            onBlur={formik.handleBlur}
          />
          {Boolean(formik.touched.phone && formik.errors.phone) ? (
            <h6 className="err_msg">
              {(formik.touched.phone && formik.errors.phone) as any}
            </h6>
          ) : (
            ""
          )}
        </div>
        <div className="form_control">
          <label>DESCRIPTION</label>
          <TextField
            hiddenLabel
            placeholder="Enter Description"
            fullWidth
            name="socialDescription"
            type="text"
            variant="outlined"
            multiline
            rows={4}
            sx={{ p: 0 }}
            className="text_field"
            id="socialDescription"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.socialDescription}
            // helperText={
            //   formik.touched.socialDescription &&
            //   formik.errors.socialDescription
            // }
          />
          {Boolean(
            formik.touched.socialDescription && formik.errors.socialDescription
          ) ? (
            <span className="helper-text">
              <h6 className="err_msg">
                {
                  (formik.touched.socialDescription &&
                    formik.errors.socialDescription) as any
                }
              </h6>
            </span>
          ) : undefined}
        </div>
        {/* <div className="form_control text_end">
          <a href="javascript:void(0)" className="btn_link">
            ADD ANOTHER ACCOUNT
          </a>
        </div> */}
        <div className="form_btn text_center">
          <Button
            className="btn btn_primary"
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setError(true)}
          >
            {socialMediaHandles !== 2 ? "Update" : "Done"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddSocialMedia;
