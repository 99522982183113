import { Route, Routes } from "react-router-dom";
import Pages from "./pages";
import ProtectedRoutes from "./utils/protectedRoutes";
import Map from "./pages/Map/Map";

const Routing = () => {
  return (
		<Routes>
			<Route path='/user' element={<Map />}  />
			<Route path='/collection_centers' element={<Map />} />

			<Route path='/' element={<Pages.LandingPage />} />
			<Route path='/signin' element={<Pages.Login />} />
			<Route path='/forgot-password' element={<Pages.ForgotPassword />} />
			<Route path='/otp' element={<Pages.Otp />} />
			<Route path='/reset-password' element={<Pages.ResetPassword />} />
			<Route path='/signup' element={<Pages.Register />} />
			<Route
				path='/profile-setup'
				element={
					<ProtectedRoutes>
						<Pages.ProfileSetup />
					</ProtectedRoutes>
				}
			/>
			<Route
				path='/marketplace-vendors'
				element={<Pages.MarketplaceVendors />}
			/>
			<Route path='/marketplace-vendors/:id' element={<Pages.VendorDetail />} />
			<Route path='/learn-how' element={<Pages.LearnHow />} />
			<Route path='/become-a-vendor' element={<Pages.BecomeVendor />} />
			<Route path='/become-partner' element={<Pages.BecomePartner />} />
			<Route path='/job-opportunities' element={<Pages.JobOpportunities />} />
			<Route path='/explore-category' element={<Pages.ExploreCategory />} />

			<Route
				path='/explore-subcategory/:catId'
				element={<Pages.ExploreSubcategory />}
			/>
			<Route
				path='/explore-subcategory/detail/:subCatId'
				element={<Pages.SubcategoryDetail />}
			/>
			<Route path='/nft-detail/:nftId' element={<Pages.NftDetail />} />
			<Route path='/cart' element={<Pages.Cart />} />
			<Route path='/account' element={<Pages.AccountScreen />} />
			<Route path='/privacy-policy' element={<Pages.PrivacyPolicy />} />
			<Route path='/disclaimer' element={<Pages.Disclaimer />} />
			<Route path='/terms' element={<Pages.About />} />
			<Route path='/signup-for-free' element={<Pages.SignupFree />} />
			<Route path='/blogs' element={<Pages.Blogs />} />
			<Route path='/blogs/detail/:id' element={<Pages.BlogDetail />} />
			<Route path='/faq' element={<Pages.FAQ />} />
			<Route path='/contact-us' element={<Pages.Contact />} />
			<Route path='/corporate-sites' element={<Pages.CorporateSites />} />
			<Route path='/news' element={<Pages.News />} />
			<Route path='/news/detail/:id' element={<Pages.NewsDetail />} />
			<Route path='/our-partners' element={<Pages.OurPartners />} />
			<Route path='/platform-nfts' element={<Pages.PlatformNFTs />} />
			{/* <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Pages.Profile />
            </ProtectedRoutes>
          }
        /> */}
		</Routes>
	)
};

export default Routing;
