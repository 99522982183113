/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const JobOpportunities_Form = () => {
    const navigate = useNavigate();

    return (
        <section className="form_sc uh_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h1>Join the Team!</h1>
                    <p>Could you please add your contact details and we will direct you to our Telegram chat for Job Applicants.</p>
                    <p>Thank you for your interest.</p>
                    <p>Make Waste not Wasted!</p>
                </div>
                <form action="" className="form">
                    <div className="form_control">
                        <label>NAME *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Name"
                            fullWidth
                            name="name"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>PREFERRED POSITION *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Position Name"
                            fullWidth
                            name="company"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>CONTACT *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Contact"
                            fullWidth
                            name="contact"
                            type="number"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>CV LINK</label>
                        <TextField
                            hiddenLabel
                            placeholder="Add Link"
                            fullWidth
                            name="role"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_btn">
                        <Button
                            className="btn btn_primary"
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default JobOpportunities_Form;
