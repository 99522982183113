import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import "./cart.scss";
import CartContent from "./features/cartContent";

const Cart = () => {

    const navigate = useNavigate();

    return (
        <Layout>
            <main className="page_cart">
                <section className="site_pagination uht_spc">
                    <div className="conta_iner">
                        <ul>
                            <li><a href="javascript:void(0)" onClick={() => navigate("/")}>Home</a></li>
                            <li>Cart</li>
                        </ul>
                    </div>
                </section>

                <CartContent />
            </main>
        </Layout>
    );
};

export default Cart;
