import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./exploreCategory.scss";
import { useNavigate } from "react-router-dom";
import ExploreCategory_Category from "./features/category";
import { useAppDispatch } from "../../hooks/store";
import { useLazyGetCategoryQuery } from "../../services/main/category";
import { Loader } from "../../helpers";

const ExploreCategory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCategoryMethod] = useLazyGetCategoryQuery();

  const [categories, setCategories] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  let totalPages = Math.ceil(totalCount / 12);

  const getCategories = async () => {
    try {
      setLoading(true);
      const result = await getCategoryMethod({
        page: page,
        size: 12,
        search: debouncedSearchTerm || "",
        pagination: true,
      }).unwrap();
      if (result?.statusCode === 200) {
        setCategories(result?.data?.category || []);
        setTotalCount(result?.data?.count || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, [page, debouncedSearchTerm]);

  return (
    <Layout>
      <main className="page_explore">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>Explore</li>
            </ul>
          </div>
        </section>

        <ExploreCategory_Category
          categories={categories}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          loading={loading}
          totalCount={totalCount}
        />
      </main>
    </Layout>
  );
};

export default ExploreCategory;
