/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from '../../layout'
import { useNavigate } from 'react-router-dom'
import PlatformNFTs_Content from './features/platformNftContent'
import { useLazyGetAllNftsQuery } from '../../services/main/category'
import { useEffect, useState } from 'react'
import { Loader, setToStorage } from '../../helpers'
import userAuth from '../../hooks/useAuth'
import { useLazyGetPassiveNftQuery } from '../../services/main/setting'
import YourHomeExplore from '../landingPage/features/yourNFTExplore'
import { useSelector } from 'react-redux'
import { getToken } from '../../reducers/authSlice'

const PlatformNFTs = () => {
	const user = userAuth()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [nfts, setNfts] = useState<any>([])
	const [userNfts, setUserNfts] = useState<any>([])
	const [page, setPage] = useState(1)
	const [totalCount, setTotalCount] = useState(1)
	const [value, setValue] = useState(0)
	const [sortSelect, setSortSelect] = useState('default')
	const [positionSelect, setPositionSelect] = useState('1')
	const [categorySelect, setCategorySelect] = useState('All')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [searchTerm, setSearchTerm] = useState('')

    const token = useSelector(getToken)

	// @ts-ignore
	const APPKEY = import.meta.env.REACT_APP_APP_KEY || ''

	let totalPages = Math.ceil(totalCount / 15)
	const [getAllUserNftsMethod] = useLazyGetPassiveNftQuery()
	const [getAllNftsMethod] = useLazyGetAllNftsQuery()
	const getAllUserNftsData = async () => {
		setIsLoading(true)
		try {
			const result = await getAllUserNftsMethod({
				page: 1,
				size: 5,
				type: 1,
                appkey: APPKEY,
                token: token,
			}).unwrap()
			if (result?.statusCode === 200) {
				setUserNfts(result?.data?.data || [])
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}

	const getAllNftsData = async () => {
		setIsLoading(true)
		try {
			const result = await getAllNftsMethod({
				page: page,
				size: 15,
				type: value + 1,
				sort: sortSelect !== 'default' ? sortSelect : '',
				sortType:
					sortSelect !== 'default'
						? // eslint-disable-next-line eqeqeq
						  positionSelect == '1'
							? 'asc'
							: 'desc'
						: '',
				categoryId:
					categorySelect !== 'default' && categorySelect !== 'All'
						? categorySelect
						: '',
				search: '',
			}).unwrap()
			if (result?.statusCode === 200) {
				setNfts(result?.data?.data || [])
				setTotalCount(result?.data?.count || 0)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		getAllNftsData()
		getAllUserNftsData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, value, debouncedSearchTerm])

	useEffect(() => {
		if (page === 1) {
			getAllNftsData()
		} else {
			setPage(1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortSelect, positionSelect, categorySelect])

	return (
		<Layout>
			<Loader isLoad={isLoading} />
			<main className='page_explore'>
				<section className='site_pagination uht_spc'>
					<div className='conta_iner'>
						<ul>
							<li>
								<a href='javascript:void(0)' onClick={() => navigate('/')}>
									Home
								</a>
							</li>
							<li>Platform Asset's</li>
						</ul>
					</div>
				</section>
				{user && (
					<div className='ec_category_sc ub_spc'>
						<div className='conta_iner'>
							<div className='s_head f_width s_head_flex'>
								<h2>
									Your Assets(
									<a
										onClick={() => setToStorage('ACTIVE_ACCOUNT_TAB', '1')}
										href='/account'
									>
										See All
									</a>
									)
								</h2>
							</div>
							<div className='subcategory_listing'>
								<div className='gap_m'>
									{userNfts.length > 0
										? userNfts?.map((item: any) => {
												return (
													<div
														className='subcategory_listing_single'
														key={item?._id}
														onClick={() => {
															navigate(
																`/nft-detail/${item?._id}?type=1&path=acc`
															)
														}}
													>
														<figure>
															<img
																src={
																	item?.image || '/images/nft_attachment_1.jpg'
																}
																alt='Image'
															/>
														</figure>
														<h3>{item?.name || ''}</h3>
														{/* <p>By remnynt</p> */}
													</div>
												)
										  })
										: undefined}
								</div>
							</div>
						</div>
					</div>
				)}
				<PlatformNFTs_Content
					nfts={nfts}
					setPage={setPage}
					page={page}
					totalPages={totalPages}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					setDebouncedSearchTerm={setDebouncedSearchTerm}
					loading={isLoading}
					totalCount={totalCount}
					setSortSelect={setSortSelect}
					sortSelect={sortSelect}
					setPositionSelect={setPositionSelect}
					positionSelect={positionSelect}
					setCategorySelect={setCategorySelect}
					categorySelect={categorySelect}
					setValue={setValue}
					value={value}
				/>
			</main>
		</Layout>
	)
}

export default PlatformNFTs
