/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
    TextField,
    Autocomplete,
    getCardContentUtilityClass,
    Select,
    MenuItem,
    Input,
} from '@mui/material';
import { CommonBody, NftResponse } from '../../../types/General';
import {
    useClaimCouponMutation,
    useHandleBidWinnerMutation,
    useSendGiftNftMutation,
    useSetOnBidMutation,
    useGetOrganizationCouponMutation,
    useUpdateCouponMutation,
} from '../../../services/main/setting';
import { generateEncryptedKeyBody } from '../../../utils/crypto';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    setToStorage,
    successToast,
} from '../../../helpers';
import { useLazyGetNftByIdQuery } from '../../../services/main/setting';
import useAuth from '../../../hooks/useAuth';
import { useMintNftMutation } from '../../../services/main/mint';
import Web3 from 'web3';
import {
    Common721AAddress,
    Common721ABI,
    CommonMarketABI,
    CommonMarketAddress,
} from '../../../utils/configuration';
import { useAppSelector } from '../../../hooks/store';
import { getCms } from '../../../reducers/generalSlice';
import { string } from 'yup';
import { getToken, setToken } from '../../../reducers/authSlice'
import { useDispatch, useSelector } from 'react-redux'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Passive_Detail = ({
    setNfts,
    nfts,
    getNftByIdData,
    vendors,
    setLoading,
    users,
    setOpen,
}: {
    nfts: NftResponse | undefined;
    getNftByIdData: () => void;
    vendors: any;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setOpen: Dispatch<SetStateAction<boolean>>;
    users: any;
    setNfts: Dispatch<SetStateAction<NftResponse | undefined>>;
}) => {
    const { nftId } = useParams();
    const navigate = useNavigate();
    const cmsData = useAppSelector(getCms);
    const tabsData = [
        {
            id: 1,
            name: 'Bid',
            view: nfts?.sellType === 1,
            value: 0,
        },
        {
            id: 2,
            name: 'Gift',
            view: nfts?.sellType === 2,
            value: 1,
        },
        {
            id: 3,
            name: 'Mint',
            view: nfts?.nftType === 2 && nfts?.sellType === 3,
            value: 2,
        },
        {
            id: 4,
            name: 'Claim Coupon',
            view: nfts?.sellType === 4 && !nfts?.couponClaimed,
            value: 3,
        },
    ];

	const token = useSelector(getToken)

	const APPKEY: string = process.env.REACT_APP_APP_KEY || '';

    const web3 = new Web3(window.ethereum);

    const CommonMarketContract = new web3.eth.Contract(
        CommonMarketABI,
        CommonMarketAddress
    );

    const Common721Contract = new web3.eth.Contract(
        Common721ABI,
        Common721AAddress
    );

    const [setOnBidMethod] = useSetOnBidMutation();
    const [handleActionMethod] = useHandleBidWinnerMutation();
    const [giftNftMethod] = useSendGiftNftMutation();
    const [claimCouponMethod] = useClaimCouponMutation();
    const [getNftByIdMethod] = useLazyGetNftByIdQuery();
    const [mintNftByIdMethod] = useMintNftMutation();
    const [updateCouponMethod] = useUpdateCouponMutation();
    const [getOrganizationCoupon] = useGetOrganizationCouponMutation();
    const [couponDetails, setCouponDetails] = useState<any>([]);
    const [value, setValue] = useState(
        !cmsData?.isWeb3Enabled ? 3 : nfts?.sellType ? nfts?.sellType - 1 : 0
    );
    const [selectedCoupon, setSelectedCoupon] = useState<any>({});
    const [selectedVendor, setSelectedVendor] = useState('');
    const [approveStatus, setApproveStatus] = useState(false);
    const [customerWallet, setCustomerWallet] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
    const [metaMaskOwner, setMetaMaskOwner] = useState('');
    const [copied, setCopied] = useState(false);
    const [showCouponSelect, setShowCouponSelect] = useState(false);
    const [couponBody, setCouponBody] = useState<{
        orgId: string;
        i2ePoint: number;
    }>({
        orgId: '',
        i2ePoint: 0,
    });

    const [couponDescription, setCouponDescription] = useState<string>('');

    const handleChangeUserSelect = (
        event: React.ChangeEvent<object>,
        value: any
    ) => {
        if (value !== null) {
            setSelectedUser(value?._id);
            setCustomerWallet(value?.walletAddress || '');
        } else {
            setSelectedUser('');
            setCustomerWallet('');
        }
    };

    const handleChangeVendorSelect = (
        event: React.ChangeEvent<object>,
        value: any
    ) => {
        if (value !== null) {
            console.log({ value });
            setSelectedVendor(value?._id);
            setShowCouponSelect(true);
            setCouponBody({
                orgId: selectedVendor,
                i2ePoint: nfts?.basePrice as number,
            });

            console.log({ selectedVendor });
            // handleGetOrganizationCouponApi();
        } else {
            setSelectedVendor('');
            setShowCouponSelect(false);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getMarket721 = async () => {
        try {
            let sResult = await Common721Contract.methods.market().call();
            return sResult;
        } catch (error: any) {
            // if (error?.data?.message || error?.message) {
            //   errorToast(error?.data?.message || error?.message);
            // }
            console.log(error);
        }
    };

    const handleIsApproved = async () => {
        let sAccounts = await web3.eth.getAccounts();
        let sOwner = sAccounts[0];
        let sMarket = await getMarket721();
        try {
            let approval = Common721Contract.methods
                .isApprovedForAll(sOwner, sMarket)
                // @ts-ignore
                .call(function (err: any, status: any) {
                    return status;
                });
            return approval;
        } catch (error: any) {
            console.log(error);
            // if (error?.data?.message || error?.message) {
            //   errorToast(error?.data?.message || error?.message);
            // }
        }
    };

    const handleCheck = async () => {
        const res = (await handleIsApproved()) as any;
        setApproveStatus(res);
        console.log(res, 'klklklk');
    };

    const handleGiftBlockchain = async () => {
        if (selectedUser === '') {
            errorToast('Select User first');
            return;
        }
        try {
            setLoading(true);
            const tokenId = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
            const receiver = customerWallet;
            const accounts = await web3.eth.getAccounts();
            let gas = await CommonMarketContract.methods
                .giftNft(receiver, tokenId)
                .estimateGas({ from: accounts[0] });
            await CommonMarketContract.methods
                .giftNft(receiver, tokenId)
                .send({ from: accounts[0], gas: String(gas) })
                .on('transactionHash', function (sHash) {
                    console.log(sHash, 'sHashhhhhhhh');
                })
                .on('receipt', function (receipt: any) {
                    setLoading(false);
                    console.log(receipt, 'llll');
                    if (receipt?.status) {
                        handleGiftNft();
                    }
                });

            setLoading(false);
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'llllll');
            setLoading(false);
        }
    };

    const handleClaimCouponBlockchain = async () => {
        if (selectedVendor === '') {
            errorToast('Select any Vendor first');
            return;
        }
        try {
            setLoading(true);
            const tokenId = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
            const accounts = await web3.eth.getAccounts();
            let gas = await CommonMarketContract.methods
                .claimCoupon(tokenId)
                .estimateGas({ from: accounts[0] });
            await CommonMarketContract.methods
                .claimCoupon(tokenId)
                .send({ from: accounts[0], gas: String(gas) })
                .on('transactionHash', function (sHash) {
                    console.log(sHash, 'sHashhhhhhhh');
                })
                .on('receipt', function (receipt: any) {
                    setLoading(false);
                    console.log(receipt, 'llll');
                    if (receipt?.status) {
                        handleClaimCoupon();
                    }
                });

            setLoading(false);
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'llllll');
            setLoading(false);
        }
    };

    const handleApproval = async (
        type: string,
        item?:
            | {
                  _id: string;
                  nftId: string;
                  amount: number;
                  bidderUserId: string;
                  createdAt: string;
                  isBlocked: boolean;
                  isDeleted: boolean;
                  nftOwnerId: string;
                  bidderWalletAddress: string;
              }
            | undefined
    ) => {
        if (selectedVendor === '' && type === 'claim') {
            errorToast('Select any Vendor first');
            return;
        }
        if (selectedUser === '' && type === 'gift') {
            errorToast('Select User first');
            return;
        }
        try {
            setLoading(true);
            let sAccounts = await web3.eth.getAccounts();
            let sMsgsender = sAccounts[0];
            let sMarket = await getMarket721();

            let gas = await Common721Contract.methods
                .setApprovalForAll(sMarket, true)
                .estimateGas({ from: sMsgsender });

            await Common721Contract.methods
                .setApprovalForAll(sMarket, true)
                .send({ from: sMsgsender, gas: String(gas) })
                .on('transactionHash', function (sHash: string) {})
                .on('receipt', function (receipt: any) {
                    setLoading(false);
                    console.log(receipt, 'llll');
                    if (receipt?.status) {
                        if (type === 'gift') {
                            handleGiftBlockchain();
                        } else if (type === 'claim') {
                            handleClaimCouponBlockchain();
                        }
                        if (type === 'bid' && item) {
                            handleAcceptBid(item);
                        }
                    }
                });
        } catch (error: any) {
            setLoading(false);
            console.log(error);
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
        }
    };

    const handleSetOnBid = async () => {
        let data = {
            basePrice: nfts?.basePrice || 0,
            bid: nfts?.sellType === 1 ? 'remove' : 'add',
        };
        try {
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;
            const result = await setOnBidMethod({
                body,
                nftId: nfts?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                getNftByIdData();
                successToast(
                    nfts?.sellType === 1
                        ? 'Remove from bid'
                        : 'Nft set on bid successfully'
                );
                // navigate(-1);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const bidSetHandle = async () => {
        const tokenId = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
        const accounts = await web3.eth.getAccounts();
        console.log(
            nfts?.nftType,
            nfts?.sellType,
            'nfts?.sellTypenfts?.sellType'
        );

        if (nfts?.nftType === 2 && nfts?.sellType === 1) {
            try {
                setLoading(true);
                let gas = await CommonMarketContract.methods
                    .removeFromBidding(tokenId)
                    .estimateGas({ from: accounts[0] });

                await CommonMarketContract.methods
                    .removeFromBidding(tokenId)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        setLoading(false);
                        console.log(receipt, 'receipt>>>>>>>');
                        handleSetOnBid();
                    });
            } catch (error: any) {
                setLoading(false);
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'llllll');
            }
        } else if (nfts?.nftType === 2 && nfts?.sellType === 0) {
            try {
                setLoading(true);
                let gas = await CommonMarketContract.methods
                    .setOnBidding(tokenId)
                    .estimateGas({ from: accounts[0] });

                await CommonMarketContract.methods
                    .setOnBidding(tokenId)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        setLoading(false);
                        console.log(receipt, 'receipt>>>>>>>');
                        handleSetOnBid();
                    });
            } catch (error: any) {
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                setLoading(false);
                console.log(error, 'llllll');
            }
        }
    };

    const handleBidAction = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        try {
            let data = {
                bidderUserId: item?.bidderUserId || 0,
                bid: type,
            };
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;
            const result = await handleActionMethod({
                body,
                nftId: nfts?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                getNftByIdData();
                successToast(
                    type === 'accept'
                        ? 'Bid accepted successfully'
                        : 'Bid rejected successfully'
                );
                if (type === 'accept') {
                    navigate(-1);
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleAcceptBid = async (item: {
        _id: string;
        nftId: string;
        amount: number;
        bidderUserId: string;
        createdAt: string;
        isBlocked: boolean;
        isDeleted: boolean;
        nftOwnerId: string;
        bidderWalletAddress: string;
    }) => {
        const id = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
        const accounts = await web3.eth.getAccounts();
        const user = item?.bidderWalletAddress;
        try {
            setLoading(true);
            console.log(id, user, accounts[0], 'lplplplpl');
            let gas = await CommonMarketContract.methods
                .acceptBid(id, user)
                .estimateGas({ from: accounts[0] });

            await CommonMarketContract.methods
                .acceptBid(id, user)
                .send({ from: accounts[0], gas: String(gas) })
                .on('transactionHash', function (sHash) {
                    console.log(sHash, 'sHashhhhhhhh');
                })
                .on('receipt', function (receipt) {
                    setLoading(false);
                    console.log(receipt, 'receipt>>>>>>>');
                    handleBidAction(item, 'accept');
                });
        } catch (error: any) {
            setLoading(false);
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'llllll');
        }
    };

    const bidAcceptReject = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        const id = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
        const accounts = await web3.eth.getAccounts();
        const user = item?.bidderWalletAddress;
        if (nfts?.nftType === 2 && type === 'reject') {
            try {
                setLoading(true);

                let gas = await CommonMarketContract.methods
                    .rejectBid(id, user)
                    .estimateGas({ from: accounts[0] });

                await CommonMarketContract.methods
                    .rejectBid(id, user)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        setLoading(false);
                        console.log(receipt, 'receipt>>>>>>>');
                        handleBidAction(item, type);
                    });
            } catch (error: any) {
                setLoading(false);
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'llllll');
            }
        } else if (nfts?.nftType === 2 && type === 'accept' && !approveStatus) {
            handleApproval('bid', item);
        } else if (nfts?.nftType === 2 && type === 'accept' && approveStatus) {
            handleAcceptBid(item);
        }
    };

    const handleGiftNft = async () => {
        if (selectedUser === '') {
            errorToast('Select User first');
            return;
        }
        let data = {
            userId: selectedUser,
        };
        try {
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;
            const result = await giftNftMethod({
                body,
                nftId: nfts?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                // getNftByIdData();
                setSelectedUser('default');
                successToast('Gift send successfully');
                navigate(-1);
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    const handleClaimCoupon = async () => {
        if (selectedVendor === '') {
            errorToast('Select any Vendor first');
            return;
        }
        if (!selectedCoupon._id) {
            errorToast('Select any Vendor first');
            return;
        }
        if (selectedCoupon?.couponType === 2) {
            if (!couponDescription) {
                errorToast('Please enter coupon description');
                return;
            }
        }
        let data = {
            nftId: nfts?._id,
            couponDescription,
        };

        try {
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;

            const result = await claimCouponMethod({
                body,
                couponId: selectedCoupon._id || '',
                token: token,
                appkey: APPKEY,
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                console.log('result: ', result);
                const res = await getNftByIdMethod({ nftId: nftId, token: token, appkey: APPKEY }).unwrap();
                if (res?.statusCode === 200) {
                    setNfts(res?.data || {});
                    getNftByIdData();
                    // if (res?.data?.coupon?.couponType === 2) {
                    //     setOpen(true);
                    // }
                }

                successToast('Coupon Claimed successfully');
                navigate(`/nft-detail/${nfts?._id}?type=3&path=acc`, {
                    replace: true,
                });
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    const handleMintApi = async () => {
        try {
            setLoading(true);
            const result = await mintNftByIdMethod({
                nftId: nfts?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                // getNftByIdData();
                successToast('Nft minted successfully');
                setToStorage(STORAGE_KEYS.activeTab, '3');
                navigate(-1);
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    const handleGetOrganizationCouponApi = async () => {
        try {
            console.log({ selectedVendor });
            const body = generateEncryptedKeyBody({
                orgId: selectedVendor,
                i2ePoint: nfts?.basePrice as number,
            }) as CommonBody;
            const result = await getOrganizationCoupon({ body }).unwrap();
            if (result.statusCode === 200) {
                setCouponDetails(result.data);
            } else {
                console.log(result)
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    const handleMintContractFunction = async () => {
        try {
            setLoading(true);
            const tokenId = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
            const tokenUri = nfts?.image;
            const i2ePoints = nfts?.basePrice || 0;
            const carbonCreditPoints = nfts?.carbonCredit || 0;
            const accounts = await web3.eth.getAccounts();
            console.log(tokenId, tokenUri, i2ePoints, carbonCreditPoints);

            let gas = await CommonMarketContract.methods
                .mintUser(tokenId, tokenUri, i2ePoints, carbonCreditPoints)
                .estimateGas({ from: accounts[0] });

            await CommonMarketContract.methods
                .mintUser(tokenId, tokenUri, i2ePoints, carbonCreditPoints)
                .send({ from: accounts[0], gas: String(gas) })

                .on('transactionHash', function (sHash) {
                    // console.log(sHash, "sHashhhhhhhh");
                })
                .on('receipt', function (receipt) {
                    setLoading(false);
                    // console.log(receipt, "receipt>>>>>>>");
                    handleMintApi();
                });
        } catch (error: any) {
            setLoading(false);
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'llllll');
        }
    };

    const handleGiftClick = async () => {
        if (nfts?.nftType === 2 && !approveStatus) {
            handleApproval('gift');
        } else if (nfts?.nftType === 2 && approveStatus) {
            handleGiftBlockchain();
        }
    };

    const handleClaimClick = async () => {
        if (nfts?.nftType === 2 && !approveStatus) {
            handleApproval('claim');
        } else if (nfts?.nftType === 2 && approveStatus) {
            handleClaimCouponBlockchain();
        }
    };

    const handleMint = async (
        type: string,
        item?:
            | {
                  bidderName?: string;
                  _id: string;
                  nftId: string;
                  amount: number;
                  bidderUserId: string;
                  createdAt: string;
                  isBlocked: boolean;
                  isDeleted: boolean;
                  nftOwnerId: string;
                  bidderWalletAddress: string;
              }
            | undefined
    ) => {
        const token = getFromStorage(STORAGE_KEYS.token);
        if (window.ethereum) {
            console.log('Metamask is installed.');
            if (window.ethereum.isConnected()) {
                console.log('Metamask is connected.');
                try {
                    const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    });
                    if (accounts.length) {
                        console.log(accounts);
                        if (token) {
                            errorToast(
                                'Please connect to a valid wallet address'
                            );
                        } else {
                            if (type === 'mint') {
                                handleMintContractFunction();
                            } else if (type === 'accept' && item) {
                                bidAcceptReject(item, 'accept');
                            } else if (type === 'reject' && item) {
                                bidAcceptReject(item, 'reject');
                            } else if (type === 'bid') {
                                bidSetHandle();
                            } else if (type === 'gift') {
                                handleGiftClick();
                            } else if (type === 'claim') {
                                handleClaimClick();
                            }
                        }
                    }
                } catch (error: any) {
                    if (error?.data?.message || error?.message) {
                        errorToast(error?.data?.message || error?.message);
                    }
                    console.log(error, 'kk');
                }
            } else {
                errorToast(
                    'You need to connect to the metamask to proceed further'
                );
                console.log('Metamask is not connected.');
            }
        } else {
            console.log('Metamask is not installed.');
        }
    };

    const getNftDetails = async () => {
        let id = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : '';
        
        try {
            let sResult = (await CommonMarketContract.methods
                .getAssetDetails(id)
                .call()) as any;
            console.log(sResult, 'fsfjksdhfjshdfj');
            setMetaMaskOwner(sResult?.owner || '');

            return sResult;
        } catch (error: any) {
            setMetaMaskOwner('');
            // if (error?.data?.message || error?.message) {
            //   errorToast(error?.data?.message || error?.message);
            // }
            console.log(error);
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(metaMaskOwner);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        } catch (error) {
            console.error('Error copying to clipboard:', error);
        }
    };

    useEffect(() => {
        // if (isMetaMaskConnected && nfts?.nftType === 2) {
            getNftDetails();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ nfts]);

    useEffect(() => {
        if (isMetaMaskConnected) {
            handleCheck();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMetaMaskConnected]);

    
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum
                .request({ method: 'eth_accounts' })
                .then((accounts: string | any[]) => {
                    if (accounts.length > 0) {
                        setIsMetaMaskConnected(true);
                    } else {
                        setIsMetaMaskConnected(false);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    setIsMetaMaskConnected(false);
                });
        } else {
            setIsMetaMaskConnected(false);
        }
    }, []);

    useEffect(() => {
        console.log({ selectedVendor }, { nfts });
        if (selectedVendor) {
            handleGetOrganizationCouponApi();
        }
    }, [selectedVendor]);

    const handleFormSubmission = (couponDescription: any) => {
        console.log(couponDescription);
        if (nfts?.nftType === 1) {
            handleClaimCoupon();
        } else {
            handleMint('claim');
        }
    };

    useEffect(()=>{
        setSelectedCoupon(null)
    },[couponBody])

    return (
        <>
            {/* <p className="links">{`Owned by: ${
        `${nfts?.ownerName || "Admin"} ${
          metaMaskOwner ? `(${metaMaskOwner})` : ""
        }` || "Admin"
      }`}</p> */}
            <p className="links">
                {`Owned by: ${
                    `${nfts?.ownerName || 'Admin'} ${
                        metaMaskOwner
                            ? `(${
                                  metaMaskOwner?.slice(0, 7) +
                                  '...' +
                                  metaMaskOwner?.slice(-6)
                              })`
                            : ''
                    }` || 'Admin'
                }`}{' '}
                {metaMaskOwner ? (
                    <>
                        <figure title="Copy">
                            <img
                                onClick={copyToClipboard}
                                src="/images/copy_icon.svg"
                                alt="Icon"
                            />
                            {copied && (
                                <span style={{ color: 'green' }}>
                                    Copied to clipboard!
                                </span>
                            )}
                        </figure>
                    </>
                ) : undefined}
            </p>
            {token && (
                <div className="custom_tabs2">
                    {nfts?.sellType ? (
                        tabsData?.map((item) => (
                            <>
                                {item?.view ? (
                                    <Tabs
                                        value={value}
                                        key={item?.id}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="basic tabs example"
                                        className="custom_tabs_links"
                                    >
                                        <Tab
                                            label={item.name || 'BID'}
                                            {...a11yProps(item?.value)}
                                        />
                                    </Tabs>
                                ) : undefined}
                            </>
                        ))
                    ) : (
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            // variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                            className="custom_tabs_links"
                        >
                            <Tab
                            style ={{display: 'none'}}
                                className={
                                    !cmsData?.isWeb3Enabled
                                        ? 'clickDisable'
                                        : ''
                                }
                                disabled={
                                    !cmsData?.isWeb3Enabled ? true : false
                                }
                                label="BID"
                                {...a11yProps(0)}
                            />
                            <Tab
                            style ={{display: 'none'}}
                                className={
                                    !cmsData?.isWeb3Enabled
                                        ? 'clickDisable'
                                        : ''
                                }
                                disabled={
                                    !cmsData?.isWeb3Enabled ? true : false
                                }
                                label="GIFT"
                                {...a11yProps(1)}
                            />
                            {nfts?.nftType === 2 ? (
                                <></>
                            ) : (
                                <Tab
                                style ={{display: 'none'}}
                                    className={
                                        !cmsData?.isWeb3Enabled
                                            ? 'clickDisable'
                                            : ''
                                    }
                                    disabled={
                                        !cmsData?.isWeb3Enabled ? true : false
                                    }
                                    label="MINT"
                                    {...a11yProps(2)}
                                />
                            )}
                            {nfts?.couponClaimed ? (
                                <></>
                            ) : (
                                <Tab label="CLAIM COUPON" {...a11yProps(3)} />
                            )}
                        </Tabs>
                    )}
                    <CustomTabPanel value={value} index={0}>
                        <div className="hd_4">
                            <p>I2E Points:</p>
                            <h3>{nfts?.basePrice || 0}</h3>
                        </div>
                        <div className="btn_flex">
                            <button
                                // onClick={() => handleMint("bid")}
                                onClick={() => {
                                    if (nfts?.nftType === 1) {
                                        handleSetOnBid();
                                    } else {
                                        handleMint('bid');
                                    }
                                }}
                                className="btn btn_primary"
                            >
                                {nfts?.sellType === 1
                                    ? 'Remove From Bid'
                                    : 'Set On Bid'}
                            </button>
                        </div>
                        {nfts?.bidding?.length ? (
                            <div className="detail_box v2">
                                <h4>OFFERS</h4>
                                <div className="table_responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>By</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {nfts?.bidding?.map((item) => (
                                            <tbody key={item?._id}>
                                                <tr>
                                                    <td>
                                                        {item?.bidderName || ''}
                                                    </td>
                                                    <td>{item?.amount || 0}</td>
                                                    <td>
                                                        {moment(
                                                            item?.createdAt
                                                        ).format('D MMM YYYY')}
                                                    </td>
                                                    <td>
                                                        <CheckCircleIcon
                                                            // onClick={() => handleMint("accept", item)}
                                                            onClick={() => {
                                                                if (
                                                                    nfts?.nftType ===
                                                                    1
                                                                ) {
                                                                    handleBidAction(
                                                                        item,
                                                                        'accept'
                                                                    );
                                                                } else {
                                                                    handleMint(
                                                                        'accept',
                                                                        item
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <CancelIcon
                                                            // onClick={() => handleMint("reject", item)}
                                                            onClick={() => {
                                                                if (
                                                                    nfts?.nftType ===
                                                                    1
                                                                ) {
                                                                    handleBidAction(
                                                                        item,
                                                                        'reject'
                                                                    );
                                                                } else {
                                                                    handleMint(
                                                                        'reject',
                                                                        item
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        ) : undefined}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <div className="form_control">
                            <label>
                                <p>
                                    <b>Choose User</b>
                                </p>
                            </label>
                            {/* <Select
              fullWidth
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              label="social-account"
              value={selectedUser}
              onChange={handleSelectUser}
            >
              <MenuItem disabled value="default">
                Users List
              </MenuItem>
              {users?.length
                ? users?.map(
                    (item: {
                      name: any;
                      _id: string;
                      email: string | undefined;
                    }) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        <h6>{`${item?.name ? item?.name : ""} `}</h6>
                        <h6>{item?.email || ""}</h6>
                      </MenuItem>
                    )
                  )
                : undefined}
            </Select> */}
                            <Autocomplete
                                className="prfl_autocmplt"
                                disablePortal
                                id="combo-box-demo"
                                onChange={handleChangeUserSelect}
                                options={users?.length ? users : []}
                                getOptionLabel={(option) =>
                                    `${option?.name} - ${option?.email}`
                                }
                                sx={{ width: 'auto' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select User"
                                    />
                                )}
                            />
                        </div>
                        <div className="btn_flex">
                            <button
                                // onClick={() => handleMint("gift")}
                                onClick={() => {
                                    if (nfts?.nftType === 1) {
                                        handleGiftNft();
                                    } else {
                                        handleMint('gift');
                                    }
                                }}
                                className="btn btn_primary"
                            >
                                Gift
                            </button>
                        </div>
                    </CustomTabPanel>
                    {nfts?.nftType === 2 ? (
                        <></>
                    ) : (
                        <CustomTabPanel value={value} index={2}>
                            <div className="btn_flex">
                                <button
                                    onClick={() => handleMint('mint')}
                                    className="btn btn_primary"
                                >
                                    Mint on Wallet
                                </button>
                            </div>
                        </CustomTabPanel>
                    )}
                    {nfts?.couponClaimed ? (
                        <></>
                    ) : (
                        <CustomTabPanel value={value} index={3}>
                            <div className="form_control">
                                <label>
                                    <p>
                                        <b>Choose a Vendor</b>
                                    </p>
                                </label>

                                <Autocomplete
                                    disablePortal
                                    className="prfl_autocmplt"
                                    id="combo-box-demo"
                                    onChange={
                                        handleChangeVendorSelect}
                                    options={vendors?.length ? vendors : []}
                                    getOptionLabel={(option) =>
                                        `${option?.companyName}`
                                    }
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Select Vendor"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {option?.image ? (
                                                    <img
                                                        src={option?.image}
                                                        alt={
                                                            option?.companyName
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                            borderRadius: '50%',
                                                            width: 24,
                                                            height: 24,
                                                            minWidth: 24,
                                                        }}
                                                    />
                                                ) : undefined}
                                                <div>
                                                    <span>
                                                        {option?.companyName ||
                                                            ''}
                                                    </span>
                                                    <small>
                                                        {option?.email || ''}
                                                    </small>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                />
                            </div>

                            {showCouponSelect && (
                                <div className="form_control">
                                    <label>
                                        <p>
                                            <b>Choose a Coupon</b>
                                        </p>
                                    </label>

                                    <Autocomplete
                                        disablePortal
                                        className="prfl_autocmplt"
                                        id="combo-box-demo"
                                        onChange={(e: any, value: any) => {
                                            console.warn(value);
                                            setSelectedCoupon(value);
                                        }}
                                        options={
                                            couponDetails?.length
                                                ? couponDetails
                                                : []
                                        }
                                        getOptionLabel={(option: any) =>
                                            selectedCoupon ?  `Discount ${option.effectiveValue}${
                                                option.discountType === 1
                                                    ? 'AED'
                                                    : '%'
                                            }`: ''
                                        }
                                        sx={{ width: 'auto' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select Vendor"
                                            />
                                        )}
                                        renderOption={(props, option: any) => (
                                            <li {...props}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {option?.image ? (
                                                        <img
                                                            src={option?.image}
                                                            alt={
                                                                option?.companyName
                                                            }
                                                            style={{
                                                                marginRight: 8,
                                                                borderRadius:
                                                                    '50%',
                                                                width: 24,
                                                                height: 24,
                                                                minWidth: 24,
                                                            }}
                                                        />
                                                    ) : undefined}
                                                    <div>
                                                        <span>
                                                            {`Discount ${
                                                                option.effectiveValue
                                                            }${
                                                                option.discountType ===
                                                                1
                                                                    ? 'AED'
                                                                    : '%'
                                                            }` || ''}
                                                        </span>
                                                        {/* <small>
                                                    {option?.email || ''}
                                                </small> */}
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    />
                                </div>
                            )}

                            {(selectedCoupon?.couponType === 2 ||
                                selectedCoupon?.couponType === 1) && (
                                <div className="form_control">
                                    <label>{selectedCoupon?.description}</label>
                                    {selectedCoupon?.couponType === 2 && (
                                        <Input
                                            style={{ width: '100%' }}
                                            className="prfl_autocmplt"
                                            placeholder="Description"
                                            onChange={(e: any) =>
                                                setCouponDescription(
                                                    e.target.value
                                                )
                                            }
                                            value={couponDescription}
                                        />
                                    )}
                                </div>
                            )}
                            <div className="btn_flex">
                                <button
                                    onClick={() => {
                                        if (nfts?.nftType === 1) {
                                            handleClaimCoupon();
                                        } else {
                                            handleMint('claim');
                                        }
                                    }}
                                    className="btn btn_primary"
                                >
                                    Claim Coupon
                                </button>
                            </div>
                        </CustomTabPanel>
                    )}
                </div>
            )}
        </>
    );
};

export default Passive_Detail;
