import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }: { children: any }) => {
  return (
    <>
      {children}
    </>
  );
};

export default Layout;
