import Layout from "../../layout";
import "./contact.scss";
import Contact_Form from "./features/form";
import GetInTouch from "./features/getInTouch";

const Contact = () => {

    return (
        <Layout>
            <main className="page_contact">
                <GetInTouch />
                <Contact_Form />
            </main>
        </Layout>
    );
};

export default Contact;
