/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SiteFilter from "../../../components/filter";
import { useLazyGetMyNftOnBidQuery } from "../../../services/main/setting";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
} from "../../../helpers";
import SitePagination from "../../../components/sitePagination";
import { NftResponse } from "../../../types/General";
import NoDataFound from "../../../components/noData";
import useAuth from "../../../hooks/useAuth";
import { useAppSelector } from "../../../hooks/store";
import { getCms } from "../../../reducers/generalSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NftOnBid = ({
  steps,
  setSteps,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const user = useAuth();
  const cmsData = useAppSelector(getCms);

  const [isListView, setIsListView] = useState(false);
  const [nfts, setNfts] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [value1, setValue1] = useState(0);
  const [sortSelect, setSortSelect] = useState("default");
  const [positionSelect, setPositionSelect] = useState("1");
  const [categorySelect, setCategorySelect] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  let totalPages = Math.ceil(totalCount / 12);

  const [getMyNftOnBidMethod] = useLazyGetMyNftOnBidQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getDataBaseNft = async () => {
    setIsLoading(true);
    try {
      const result = await getMyNftOnBidMethod({
        page: page,
        size: 12,
        type: value1 + 1,
        sort: sortSelect !== "default" ? sortSelect : "",
        sortType:
          sortSelect !== "default"
            ? // eslint-disable-next-line eqeqeq
              positionSelect == "1"
              ? "asc"
              : "desc"
            : "",
        categoryId:
          categorySelect !== "default" && categorySelect !== "All"
            ? categorySelect
            : "",
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
        setTotalCount(result?.data?.count || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 1) {
      handleMintClick();
    } else {
      setValue1(newValue);
      setIsListView(false);
      setSortSelect("default");
      setPositionSelect("1");
      setCategorySelect("All");
    }
  };

  useEffect(() => {
    getDataBaseNft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, value1, sortSelect, positionSelect, categorySelect]);

  const handleMintClick = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (window.ethereum) {
      console.log("Metamask is installed.");
      if (window.ethereum.isConnected()) {
        console.log("Metamask is connected.");
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length) {
            console.log(accounts);
            if (token && accounts[0] !== user?.walletAddress) {
              errorToast("Please connect to a valid wallet address");
            } else {
              setValue1(1);
              setIsListView(false);
              setSortSelect("default");
              setPositionSelect("1");
              setCategorySelect("All");
              if (window) {
                window.scrollTo({ top: 0, behavior: "auto" });
              }
            }
          }
        } catch (err) {
          console.log(err, "kk");
        }
      } else {
        errorToast("You need to connect to the metamask to proceed further");
        console.log("Metamask is not connected.");
      }
    } else {
      console.log("Metamask is not installed.");
    }
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <h3>Asset On Bid</h3>
      <div>
        <div className="custom_tabs">
          <Tabs
            value={value1}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Passive" {...a11yProps(0)} />
            <Tab
              className={!cmsData?.isWeb3Enabled ? "clickDisable" : ""}
              disabled={!cmsData?.isWeb3Enabled ? true : false}
              label="Minted"
              {...a11yProps(1)}
            />
          </Tabs>
          <CustomTabPanel value={value1} index={0}>
            <SiteFilter
              setCategorySelect={setCategorySelect}
              categorySelect={categorySelect}
              setPositionSelect={setPositionSelect}
              sortSelect={sortSelect}
              setSortSelect={setSortSelect}
              positionSelect={positionSelect}
              setIsListView={setIsListView}
              isListView={isListView}
            />
            <div
              className={
                !isListView
                  ? "subcategory_listing"
                  : "subcategory_listing list_view"
              }
            >
              <div className="gap_m">
                {nfts?.length
                  ? nfts?.map((item: NftResponse) => (
                      <div
                        className={`subcategory_listing_single ${
                          item?.couponClaimed ? "claimed" : ""
                        }`}
                        onClick={() =>
                          navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
                        }
                      >
                        <figure>
                          <img
                            src={
                              item?.image || "/images/explore_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <h3>{item?.name || ""}</h3>
                        <p>{item?.category?.name || "By remnynt"}</p>
                      </div>
                    ))
                  : undefined}
              </div>
              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={1}>
            <SiteFilter
              setCategorySelect={setCategorySelect}
              categorySelect={categorySelect}
              setPositionSelect={setPositionSelect}
              sortSelect={sortSelect}
              setSortSelect={setSortSelect}
              positionSelect={positionSelect}
              setIsListView={setIsListView}
              isListView={isListView}
            />
            <div
              className={
                !isListView
                  ? "subcategory_listing"
                  : "subcategory_listing list_view"
              }
            >
              <div className="gap_m">
                {nfts?.length
                  ? nfts?.map((item: NftResponse) => (
                      <div
                        className={`subcategory_listing_single ${
                          item?.couponClaimed ? "claimed" : ""
                        }`}
                        onClick={() =>
                          navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
                        }
                      >
                        <figure>
                          <img
                            src={
                              item?.image || "/images/explore_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <h3>{item?.name || ""}</h3>
                        <p>{item?.category?.name || "By remnynt"}</p>
                      </div>
                    ))
                  : undefined}
              </div>
              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </CustomTabPanel>
        </div>
      </div>
      {!isLoading && !nfts?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </>
  );
};

export default NftOnBid;
