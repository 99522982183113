/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const LearnHow_Hero = () => {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <section className="learnHow_hero uh_spc">
      <div className="conta_iner">
        <div className="gap_p aic">
          <div className="left_s s_hd text_white">
            <p>
              LEARN HOW WE ACTIVELY REWARD SUSTAINABLE RECYCLING BEHAVIORS FOR
              CONSUMERS & BUSINESSES
            </p>
            <h2>Rewarding sustainability, one deposit at a time.</h2>
            {user ? undefined : (
              <button
                className="btn btn_lg btn_white"
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </button>
            )}
          </div>
          <div className="right_s">
            <figure>
              <img src="/images/learn_attachment_2.png" alt="Image" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnHow_Hero;
