/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useLazyGetOrganizationListQuery } from "../../../services/cms";
import { useEffect, useState } from "react";
import { Organizations } from "../../../types/General";
import SitePagination from "../../../components/sitePagination";
import NoDataFound from "../../../components/noData";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validation";
import { becomeVendorLink } from "../../../helpers/constants/urls";

type orgProps = {
  type: string;
};

const HomeVendors = ({ type }: orgProps) => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const size = 12;
  let totalPages = Math.ceil(totalCount / size);

  const navigate = useNavigate();
  const [getVendorsMethod] = useLazyGetOrganizationListQuery();
  const [vendorsList, setVendorsList] = useState<Organizations[]>([]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllVendorsList = async () => {
    try {
      const res = await getVendorsMethod({
        b2bType: type === "partner" ? 2 : 1,
        page: page,
        size: size,
        pagination: true,
        search: debouncedSearchTerm ? debouncedSearchTerm.trim() : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setVendorsList(res?.data?.organisation || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVendorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedSearchTerm, type]);

  return (
    <>
      {type ? (
        <section className="home_vendor_sc u_spc">
          <div className="conta_iner">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => {
                setSearchTerm("");
                setDebouncedSearchTerm("");
              }}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </div>
        </section>
      ) : undefined}
      {vendorsList?.length ? (
        <section className="home_vendor_sc u_spc">
          <div className="conta_iner">
            {!type ? (
              <div className="s_head text_center">
                <h2>Our Vendors</h2>
              </div>
            ) : undefined}

            {type ? undefined : (
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                // centeredSlides
                loop={true}
                navigation
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    centeredSlides: false,
                  },
                  576: {
                    slidesPerView: 2,
                    centeredSlides: false,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                className="vendor_listing hd_4 inline_arrow"
              >
                {vendorsList?.length && !type
                  ? vendorsList?.map((item, index) => (
                      <SwiperSlide>
                        <div className="vendor_single">
                          <figure>
                            <img
                              src={
                                item?.image ||
                                "/images/partner_attachment_1.jpg"
                              }
                              alt="Image"
                            />
                          </figure>
                          <h3>{item?.companyName || ""}</h3>
                          {/* <p>{item?.description?.slice(0, 50) || ""}</p> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.description || "-",
                            }}
                          ></p>

                          <a
                            // href="javascript:void(0)"
                            className="btn_link"
                            onClick={() =>
                              navigate(`/marketplace-vendors/${item?._id}`, {
                                state:
                                  type === "partner" ? "partner" : "vendor",
                              })
                            }
                          >
                            See More <EastIcon />
                          </a>
                        </div>
                      </SwiperSlide>
                    ))
                  : undefined}
              </Swiper>
            )}

            {type ? (
              <div className="gap_m">
                {vendorsList?.length && type
                  ? vendorsList?.map((item, index) => (
                      <div className="vendor_single">
                        <figure>
                          <img
                            src={
                              item?.image || "/images/partner_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <h3>{item?.companyName || ""}</h3>
                        {/* <p>{item?.description?.slice(0, 50) || ""}</p> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description || "-",
                          }}
                        ></p>

                        <a
                          // href="javascript:void(0)"
                          className="btn_link"
                          onClick={() =>
                            navigate(`/marketplace-vendors/${item?._id}`, {
                              state: type === "partner" ? "partner" : "vendor",
                            })
                          }
                        >
                          See More <EastIcon />
                        </a>
                      </div>
                    ))
                  : undefined}
              </div>
            ) : undefined}

            {vendorsList?.length > 12 && type ? (
              <SitePagination
                module={vendorsList}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                setPage={setPage}
              />
            ) : undefined}

            {vendorsList?.length && !type ? (
              <div className="btn_flex vendr_Btn">
                <button
                  onClick={() =>
                    navigate("/marketplace-vendors", { state: "vendor" })
                  }
                  className="btn btn_lg btn_primary"
                >
                  See All
                </button>

                <button
                  // onClick={() =>
                  //   navigate("/marketplace-vendors", { state: "vendor" })
                  // }
                  onClick={() => {
                    if (window) {
                      window.open(becomeVendorLink, "_self");
                    }
                  }}
                  className="btn btn_lg btn_primary"
                >
                  Become Vendor
                </button>
              </div>
            ) : undefined}
          </div>
        </section>
      ) : type ? (
        <section className="home_vendor_sc u_spc">
          <NoDataFound image={"/images/noData.png"} text="No Data Found" />
        </section>
      ) : (
        <section className="home_vendor_sc u_spc"></section>
      )}
    </>
  );
};

export default HomeVendors;
