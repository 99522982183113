/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SiteFilter from "../../../components/filter";
import { Loader } from "../../../helpers";
import { NftResponse } from "../../../types/General";
import SitePagination from "../../../components/sitePagination";
import NoDataFound from "../../../components/noData";
import { useLazyGetMintedNftQuery } from "../../../services/main/mint";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MintedNft = (
  {
    steps,
    setSteps,
  }: { steps: number; setSteps: Dispatch<SetStateAction<number>> },
  { view }: { view: any }
) => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [isListView, setIsListView] = useState(false);
  const [nfts, setNfts] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [sortSelect, setSortSelect] = useState("default");
  const [positionSelect, setPositionSelect] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState("All");
  let totalPages = Math.ceil(totalCount / 12);

  const [getMintedNftQuery] = useLazyGetMintedNftQuery();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getMintedNft = async () => {
    setIsLoading(true);
    try {
      const result = await getMintedNftQuery({
        page: page,
        size: 12,
        type: value + 1,
        sort: sortSelect !== "default" ? sortSelect : "",
        sortType:
          sortSelect !== "default"
            ? // eslint-disable-next-line eqeqeq
              positionSelect == "1"
              ? "asc"
              : "desc"
            : "",
        categoryId:
          categorySelect !== "default" && categorySelect !== "All"
            ? categorySelect
            : "",
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
        setTotalCount(result?.data?.count || 0);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMintedNft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, value, sortSelect, positionSelect, categorySelect]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsListView(false);
    setSortSelect("default");
    setPositionSelect("1");
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <h3>My Minted Assets</h3>
      <div className="custom_tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="Personal" {...a11yProps(0)} />
          <Tab label="Purchased" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <SiteFilter
            setPositionSelect={setPositionSelect}
            sortSelect={sortSelect}
            setSortSelect={setSortSelect}
            positionSelect={positionSelect}
            setIsListView={setIsListView}
            isListView={isListView}
            setCategorySelect={setCategorySelect}
            categorySelect={categorySelect}
          />
          <div
            className={
              !isListView
                ? "subcategory_listing"
                : "subcategory_listing list_view"
            }
          >
            <div className="gap_m">
              {nfts?.length
                ? nfts?.map((item: NftResponse) => (
                    <div
                      className={`subcategory_listing_single ${
                        item?.couponClaimed ? "claimed" : ""
                      }`}
                      onClick={() =>
                        navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
                      }
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/explore_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.categoryId?.name || "By remnynt"}</p>
                    </div>
                  ))
                : undefined}

              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SiteFilter
            setPositionSelect={setPositionSelect}
            sortSelect={sortSelect}
            setSortSelect={setSortSelect}
            positionSelect={positionSelect}
            setIsListView={setIsListView}
            isListView={isListView}
            setCategorySelect={setCategorySelect}
            categorySelect={categorySelect}
          />
          <div
            className={
              !isListView
                ? "subcategory_listing"
                : "subcategory_listing list_view"
            }
          >
            <div className="gap_m">
              {nfts?.length
                ? nfts?.map((item: NftResponse) => (
                    <div
                      className={`subcategory_listing_single ${
                        item?.couponClaimed ? "claimed" : ""
                      }`}
                      onClick={() =>
                        navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
                      }
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/explore_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.categoryId?.name || "By remnynt"}</p>
                    </div>
                  ))
                : undefined}

              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </div>
        </CustomTabPanel>
      </div>

      {!isLoading && !nfts?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </>
  );
};

export default MintedNft;
