/* eslint-disable jsx-a11y/img-redundant-alt */
import { CommonBody, NftResponse } from '../../../types/General';
import moment from 'moment';
import { useLocation} from 'react-router-dom'
import { TextField } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isValidInput } from '../../../utils/validation';
import { Loader, errorToast, successToast } from '../../../helpers';
import { useUpdateCouponMutation } from '../../../services/main/setting';
import { generateEncryptedKeyBody } from '../../../utils/crypto';
import { getToken, setToken } from '../../../reducers/authSlice'
import { useDispatch, useSelector } from 'react-redux'

const Minted_Detail = ({
    nfts,
    getNftByIdData,
    setCouponShortNote,
}: {
    nfts: NftResponse | undefined;
    getNftByIdData: () => void;
    setCouponShortNote: Dispatch<SetStateAction<string>>;
}) => {
	const [couponDes, setCouponDes] = useState('')

	const [updateCouponMethod, { isLoading }] = useUpdateCouponMutation()

	const token = useSelector(getToken)

	const APPKEY: string = process.env.REACT_APP_APP_KEY || '';

	const handleAddDesc = async () => {
		if (!couponDes) {
			errorToast('Add Coupon Description first')
			return
		}
		let data = {
			couponDescription: couponDes,
		}
		try {
			const body = generateEncryptedKeyBody(data) as CommonBody
			const result = await updateCouponMethod({
				body,
				claimCouponId: nfts?.claimedCouponId || '',
				token: token,
				appkey: APPKEY,
			}).unwrap()
			if (result?.statusCode === 200) {
				getNftByIdData()
				setCouponDes('')
				successToast('Description added successfully')
			}
		} catch (error: any) {
			errorToast(error?.data?.message || '')
			console.log(error)
		}
	}

	return (
		<>
			<Loader isLoad={isLoading} />
			{/* <p className="links">Owned by 0xd4aa3afc04d8cca8a09ee32f6825d</p> */}
			{/* <ul className="favs_views">
        <li>
          <RemoveRedEyeIcon /> 419 views
        </li>
        <li>
          <FavoriteIcon /> 9 favorites
        </li>
        <li>
          <img src="/images/circleStar_icon.svg" alt="Icon" /> 200{" "}
        </li>
      </ul> */}
			<div className='p_group'>
				<p className='f_med'>
					<b>Coupon Code :</b>{' '}
					{nfts?.coupon?.couponCode ? `#${nfts?.coupon?.couponCode}` : ''}
				</p>
				<p className='f_med'>
					<b>Coupon Claimed on: </b>
					{moment(nfts?.couponClaimedOn).format('MMMM Do YYYY, h:mm a')}{' '}
					{/* 23/12/2023 at 7:16pm */}
				</p>
			</div>
			<div className='coupon_card_dtl'>
				<figure>
					<img src='/images/bar_code.svg' alt='' />
					<span>Gift Voucher</span>
				</figure>
				<div className='coupon_dtl text_center'>
					<figure className='uploaded_coupon'>
						<img
							src={nfts?.coupon?.couponImage ?? nfts?.companyImage ?? ''}
							alt=''
						/>
					</figure>
					{/* <h2>
            Special <span className="d_block">Offer</span>
          </h2>
          <p>{`Valid upto ${moment(nfts?.couponEndDate).format(
            "DD MMM YYYY"
          )}`}</p> */}
				</div>
				<div className='coupon_btn'>
					<button className='btn btn_primary'>
						<span>
							{nfts?.coupon?.couponDiscountType === 1
								? `AED ${nfts?.coupon?.couponEffectiveValue || '0'}`
								: `${nfts?.coupon?.couponEffectiveValue || '0'}%`}
						</span>
					</button>
				</div>
			</div>

			{nfts?.coupon?.description && nfts?.coupon?.couponType === 1 ? (
				<div className='detail_box'>
					<h4>Description</h4>
					<p>{nfts?.coupon?.description || ''}</p>
				</div>
			) : undefined}

			{nfts?.coupon?.couponType === 2 &&
			!nfts?.claimedCoupon?.couponDescription ? (
				<div className='detail_box'>
					<h4>Please Provide Input</h4>
					<p>{nfts?.coupon?.description || ''}</p>
					<TextField
						value={couponDes}
						onChange={(val: any) => {
							if (isValidInput(val.target.value)) {
								setCouponDes(val.target.value)
							}
						}}
						hiddenLabel
						placeholder='Add Coupon Description'
						className='txt_inpt'
						fullWidth
						variant='outlined'
					/>
					<div className='btn_flex'>
						<button onClick={handleAddDesc} className='btn btn_primary'>
							Submit
						</button>
					</div>
				</div>
			) : undefined}

			{/* {nfts?.coupon?.description && !nfts?.claimedCoupon?.couponDescription ? (
        <div className="detail_box">
          <h4>Description</h4>
          <p>{nfts?.coupon?.description || ""}</p>
        </div>
      ) : undefined} */}
			{/* {nfts?.coupon?.couponType === 2 &&
      !nfts?.claimedCoupon?.couponDescription ? (
        <div className="detail_box">
          
        </div>
      ) : undefined} */}
			{nfts?.coupon?.couponType === 2 &&
			nfts?.claimedCoupon?.couponDescription ? (
				<div className='detail_box'>
					<h4>Coupon Description</h4>
					<p>{nfts?.claimedCoupon?.couponDescription || ''}</p>
				</div>
			) : undefined}
		</>
	)
};

export default Minted_Detail;
