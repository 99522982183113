/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";

const CartContent = () => {
    const navigate = useNavigate();

    return (
        <section className="cart_sc uhb_spc">
            <div className="conta_iner">
                <div className="cart_box">
                    <div className="hd_4">
                        <h1>My Cart</h1>
                    </div>
                    <div className="gap_p">
                        <div className="left_s">
                            <figure><img src="/images/category_attachment_2.jpg" alt="Image" /></figure>
                        </div>
                        <div className="right_s hd_4">
                            <h2>Uni waste</h2>
                            <p>By remnynt</p>
                            <h3>PRICE 1040</h3>
                            <button className="btn btn_primary">Checkout</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CartContent;
