import Layout from "../../../layout";
import ForgotPasswordContent from "./features/forgotPassword";
import "../../auth/auth.scss";

const ForgotPassword = () => {
  return (
    <Layout>
      <main>
        <ForgotPasswordContent />
      </main>
    </Layout>
  );
};

export default ForgotPassword;
