import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useLazyGetCategoryQuery } from "../services/main/category";
import { errorToast } from "../helpers";

const SiteFilter = ({
  setIsListView,
  isListView,
  sortSelect,
  setSortSelect,
  positionSelect,
  setPositionSelect,
  categorySelect,
  setCategorySelect,
  type,
}: {
  setIsListView?: Dispatch<SetStateAction<boolean>> | undefined;
  isListView?: boolean | undefined;
  sortSelect: string;
  setSortSelect: Dispatch<SetStateAction<string>>;
  positionSelect: string;
  setPositionSelect: Dispatch<SetStateAction<string>>;
  setCategorySelect: Dispatch<SetStateAction<string>>;
  categorySelect: string;
  type?: string | undefined;
}) => {
  const [getCategoryMethod] = useLazyGetCategoryQuery();

  const [categories, setCategories] = useState<any>([]);

  // let totalPages = Math.ceil(totalCount / 12);

  const getCategories = async () => {
    try {
      const result = await getCategoryMethod({
        page: 1,
        size: 1,
        search: "",
        pagination: false,
      }).unwrap();
      if (result?.statusCode === 200) {
        setCategories(result?.data?.category || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange1 = (event: SelectChangeEvent) => {
    setCategorySelect(event.target.value as string);
  };

  const handleChange2 = (event: SelectChangeEvent) => {
    setSortSelect(event.target.value as string);
  };

  const handleChange3 = (event: SelectChangeEvent) => {
    if (sortSelect === "default") {
      errorToast("Please select sort type first");
      return;
    }
    setPositionSelect(event.target.value as string);
  };

  return (
    <div className="filter_sort">
      <form className="form">
        <div className="form_control">
          <label>Filter by:</label>
          <Select fullWidth value={categorySelect} onChange={handleChange1}>
            {/* <MenuItem disabled value={"default"}>
              Category
            </MenuItem> */}
            <MenuItem value={"All"}>All</MenuItem>
            {categories?.length
              ? categories?.map((item: any) => (
                  <MenuItem key={item?._id} value={item?._id}>
                    {item?.name || ""}
                  </MenuItem>
                ))
              : undefined}
          </Select>
        </div>
        <div className="form_control">
          <label>Sort:</label>
          <Select fullWidth value={sortSelect} onChange={handleChange2}>
            <MenuItem disabled value={"default"}>
              Sort
            </MenuItem>
            <MenuItem value={1}>By date</MenuItem>
            <MenuItem value={2}>By I2E Points</MenuItem>
            <MenuItem value={3}>By Carbon Credit Points</MenuItem>
          </Select>
          <Select fullWidth value={positionSelect} onChange={handleChange3}>
            <MenuItem value={1}>Ascending</MenuItem>
            <MenuItem value={2}>Descending </MenuItem>
          </Select>
        </div>
        {sortSelect !== "default" ||
        (categorySelect !== "default" && categorySelect !== "All") ? (
          <p
            className="btn_link c_danger"
            onClick={() => {
              setSortSelect("default");
              setPositionSelect("1");
              setCategorySelect("All");
            }}
          >
            Clear filter
          </p>
        ) : undefined}
      </form>
      {type && type === "explore" ? undefined : (
        <div className="view_type">
          <label>View Type:</label>
          <div className="btn_flex">
            <button
              onClick={() => {
                if (setIsListView !== undefined) {
                  setIsListView(false);
                }
              }}
              className={!isListView ? "btn_rounded active" : "btn_rounded"}
            >
              <GridViewIcon />
            </button>
            <button
              onClick={() => {
                if (setIsListView !== undefined) {
                  setIsListView(true);
                }
              }}
              className={!isListView ? "btn_rounded" : "btn_rounded active"}
            >
              <ViewListIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteFilter;
