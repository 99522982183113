/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import {
  useLazyClearAllActivitiesQuery,
  useLazyGetMyActivitiesQuery,
} from "../../../services/main/setting";
import moment from "moment";
import NoDataFound from "../../../components/noData";
import { Loader } from "../../../helpers";
import SitePagination from "../../../components/sitePagination";

const MyActivities = ({
  steps,
  setSteps,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
}) => {
  const [olderActivities, setOlderActivities] = useState<any>([]);
  const [recentActivities, setRecentActivities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  let totalPages = Math.ceil(totalCount / 5);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [getMyActivitiesMethod] = useLazyGetMyActivitiesQuery();
  const [clearAllActivitiesMethod] = useLazyClearAllActivitiesQuery();

  const getActivitiesDetails = async () => {
    setIsLoading(true);
    try {
      const result = await getMyActivitiesMethod({
        page: page,
        size: 5,
      }).unwrap();
      if (result?.statusCode === 200) {
        setOlderActivities(result?.data?.olderNotifications || []);
        setRecentActivities(result?.data?.recentNotifications || []);
        setTotalCount(result?.data?.oldNotificationCount || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleAllClear = async () => {
    setIsLoading(true);
    try {
      const result = await clearAllActivitiesMethod({}).unwrap();
      if (result?.statusCode === 200) {
        setOlderActivities([]);
        setRecentActivities([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getActivitiesDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="head_flex">
        <h3>My Activities</h3>
        {/* <button onClick={handleAllClear} className="btn btn_primary">
          Clear All
        </button> */}
      </div>
      <div className="activities_list">
        {recentActivities?.length ? <h4>Recent Activities</h4> : undefined}
        <ul>
          {recentActivities?.length
            ? recentActivities?.map((item: any) => (
                <li key={item?._id}>
                  <figure>
                    <img
                      src={item?.nftImage || "/images/gift_icon.svg"}
                      alt=""
                    />
                  </figure>
                  <p>
                    <strong>
                      {item?.title || ""}{" "}
                      <span>{moment(item?.createdAt).fromNow()}</span>
                    </strong>
                    <span>{item?.message || ""}</span>
                  </p>
                </li>
              ))
            : undefined}
        </ul>

        {olderActivities?.length ? <h4>Older Activities</h4> : undefined}
        <ul>
          {olderActivities?.length
            ? olderActivities?.map((item: any) => (
                <li key={item?._id}>
                  <figure>
                    <img
                      src={item?.nftImage || "/images/gift_icon.svg"}
                      alt=""
                    />
                  </figure>
                  <p>
                    <strong>
                      {item?.title || ""}{" "}
                      <span>{moment(item?.createdAt).fromNow()}</span>
                    </strong>
                    <span>{item?.message || ""}</span>
                  </p>
                </li>
              ))
            : undefined}
        </ul>
      </div>

      {olderActivities?.length ? (
        <SitePagination
          module={olderActivities}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          setPage={setPage}
        />
      ) : undefined}

      {!isLoading && !olderActivities?.length && !recentActivities?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </>
  );
};

export default MyActivities;
