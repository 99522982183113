/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/store";
import { getCms } from "../../../reducers/generalSlice";

const GetInTouch = () => {
  const getCmsData = useAppSelector(getCms);

  const navigate = useNavigate();

  return (
    <section className="getInTouch_sc">
      <figure>
        <img src="/images/contact_banner_attachment.jpg" alt="" />
      </figure>
      <div className="conta_iner">
        <div className="inner uht_spc ub_spc">
          <div className="s_head text_center">
            <h2>Get in Touch</h2>
            <p>Feel free to drop us a line below!</p>
          </div>
          <div className="gap_p">
            <div className="single">
              <figure>
                <img src="/images/email_icon.svg" alt="Icon" />
              </figure>
              <h3>EMAIL ADDRESS</h3>
              <p>
                <Link to={`mailto:${getCmsData?.email || ""}`}>
                  {getCmsData?.email || ""}
                </Link>
              </p>
            </div>
            <div className="single">
              <figure>
                <img src="/images/mobile_icon.svg" alt="Icon" />
              </figure>
              <h3>PHONE NUMBER</h3>
              <p>
                <Link to={`tel:${getCmsData?.phone || ""}`}>
                  {getCmsData?.countryCode
                    ? (getCmsData?.countryCode.includes("+") ? "" : "+") +
                      getCmsData?.countryCode
                    : null}{" "}
                  {getCmsData?.phone || "--"}
                </Link>
              </p>
            </div>
            <div className="single">
              <figure>
                <img src="/images/contact_search_icon.svg" alt="Icon" />
              </figure>
              <h3>OUR LOCATION</h3>
              <p>{getCmsData?.address || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
