/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLazyGetOrganizationListQuery } from "../../../services/cms";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Organizations } from "../../../types/General";

const VendorDetail_Content = ({
  vendorsData,
  setSelectedId,
  type,
}: {
  vendorsData: Organizations | undefined;
  setSelectedId: Dispatch<SetStateAction<string>>;
  type: string;
}) => {
  const [getVendorsMethod] = useLazyGetOrganizationListQuery();

  const [vendorsList, setVendorsList] = useState<Organizations[]>([]);

  const getAllVendorsList = async () => {
    try {
      const res = await getVendorsMethod({
        b2bType: type === "partner" ? 2 : 1,
        page: 1,
        size: 3,
        pagination: false,
      }).unwrap();
      if (res?.statusCode === 200) {
        setVendorsList(res?.data?.organisation || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVendorsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="vendorDetail_sc ub_spc">
      <div className="conta_iner">
        <div className="gap_p">
          <div className="left_s">
            <div className="vendorDetail_list hd_4">
              <h2>{type === "partner" ? "Other Partners" : "Other Vendors"}</h2>
              <ul>
                {vendorsList?.length
                  ? vendorsList?.map((item, index) => (
                      <li onClick={() => setSelectedId(item?._id)} key={index}>
                        <figure>
                          <img
                            src={
                              item?.image || "/images/partner_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <span>{item?.companyName || ""}</span>
                      </li>
                    ))
                  : undefined}
              </ul>
            </div>
          </div>
          <div className="right_s">
            <div className="vendorDetail_content">
              <div
                style={{
                  backgroundImage: `url(${vendorsData?.bannerImage})`,
                }}
                className="top_box"
              >
                {/* <div className="d_flex">
                  <figure>
                    <img
                      src={vendorsData?.image || "/images/attachment_03.png"}
                      alt="Image"
                    />
                  </figure>
                  <div className="s_hd text_white">
                    <h2>{vendorsData?.companyName || ""}</h2>
               <p>We pick you junk in no-time</p>
                    <button className="btn btn_primary">
                      Free Junk Removal
                    </button> 
                  </div>
                </div> */}
              </div>
              <div className="hd_3 d_flex">
                <h2>{vendorsData?.companyName || ""}</h2>
                <button
                  onClick={() => {
                    if (window) {
                      window.open(
                        vendorsData?.companyWebSite?.includes("http")
                          ? vendorsData?.companyWebSite
                          : `https://${vendorsData?.companyWebSite}`,
                        "_blank"
                      );
                    }
                  }}
                  className="btn btn_lg btn_primary"
                >
                  Visit Site
                </button>
              </div>
              {type === "partner" ? undefined : (
                <>
                  <h3>{vendorsData?.offer ? "Offer" : ""}</h3>
                  {/* <p>{vendorsData?.offer || ""}</p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: vendorsData?.offer || "-",
                    }}
                  ></p>
                </>
              )}
              <h3>
                {vendorsData?.description
                  ? type === "partner"
                    ? "Partner Description"
                    : "Vendor Description"
                  : ""}
              </h3>
              {/* <p>{vendorsData?.description || ""}</p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: vendorsData?.description || "-",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VendorDetail_Content;
