import React from "react";
import Layout from "../../layout";
import HomePartner from "../landingPage/features/partner";

const OurPartners = () => {
  return (
    <Layout>
      <main className="page_partner">
        <HomePartner type="partner" />
      </main>
    </Layout>
  );
};

export default OurPartners;
