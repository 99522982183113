/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./news.scss";
import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import NewsBanner from "./features/newsBanner";
import NewsGrid from "./features/newsGrid";
import { useLazyGetAllNewsQuery } from "../../services/cms";
import { useEffect, useState } from "react";
import { Blog } from "../../types/General";
import NoDataFound from "../../components/noData";

const News = () => {
  const navigate = useNavigate();

  const [getNews] = useLazyGetAllNewsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [news, setNews] = useState<Blog[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllNews = async () => {
    try {
      setLoading(true);
      const res = await getNews({
        search: debouncedSearchTerm.trim(),
        page: 1,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setNews(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
  }, [debouncedSearchTerm]);

  return (
    <Layout>
      <main className="page_news">
        <NewsBanner />
        {news?.length ? (
          <NewsGrid
            news={news}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            loading={loading}
          />
        ) : loading ? undefined : (
          <section className="newsGrid_sc u_spc">
            <NoDataFound image={"/images/noData.png"} text="No Data Found" />
          </section>
        )}
      </main>
    </Layout>
  );
};

export default News;
