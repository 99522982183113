/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const HomeSteps = () => {
    const navigate = useNavigate();

    return (
        <section className="home_steps_sc u_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h2>3 Easy Steps to Recycle and Earn Asset</h2>
                </div>
                <div className="gap_m hd_5">
                    <div className="step_box_single">
                        <figure><img src="/images/step_attachment_1.png" alt="Image" /></figure>
                        <h2>Go to Dropoff Site</h2>
                        <p>Al chatbot points you to the nearest e-waste collection site</p>
                        <button className="btn_rounded" onClick={() => navigate("/learn-how")}><ArrowForwardIosIcon /></button>
                    </div>
                    <div className="step_box_single">
                        <figure><img src="/images/step_attachment_2.png" alt="Image" /></figure>
                        <h2>Take Photo of Deposit</h2>
                        <p>Al identifies and categorizes e-waste via captured photo</p>
                        <button className="btn_rounded" onClick={() => navigate("/learn-how")}><ArrowForwardIosIcon /></button>
                    </div>
                    <div className="step_box_single">
                        <figure><img src="/images/step_attachment_3.png" alt="Image" /></figure>
                        <h2>Recycle & Earn</h2>
                        <p>Recycle & earn rewards which can be redeemed for real value.</p>
                        <button className="btn_rounded" onClick={() => navigate("/learn-how")}><ArrowForwardIosIcon /></button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeSteps;
