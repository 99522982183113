/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import SiteFilter from '../../../components/filter';
import {
    useLazyGetPassiveNftQuery,
    useLazyGetMyWalletQuery,
} from '../../../services/main/setting';
import { Loader } from '../../../helpers';
import { MyWallet, NftResponse } from '../../../types/General';
import SitePagination from '../../../components/sitePagination';
import NoDataFound from '../../../components/noData';
import { setToken } from '../../../reducers/authSlice'
import { useDispatch } from 'react-redux'



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PassiveNft = (
    {
        steps,
        setSteps,
    }: { steps: number; setSteps: Dispatch<SetStateAction<number>> },
    { view }: { view: any }
) => {
	const navigate = useNavigate()
	const [myWallet] = useLazyGetMyWalletQuery()
	const [value, setValue] = useState(0)
	const [isListView, setIsListView] = useState(false)
	const [nfts, setNfts] = useState<any>([])
	const [page, setPage] = useState(1)
	const [totalCount, setTotalCount] = useState(1)
	const [sortSelect, setSortSelect] = useState('default')
	const [positionSelect, setPositionSelect] = useState('1')
	const [isLoading, setIsLoading] = useState(false)
	const [categorySelect, setCategorySelect] = useState('All')
	const [walletData, setWalletData] = useState<MyWallet>()
	let totalPages = Math.ceil(totalCount / 12)
	const location = useLocation()

	const searchParams = new URLSearchParams(location.search)
	const token = searchParams.get('token') || ''

	const dispatch = useDispatch()

	const handleSetToken = (token: string | null) => {
		dispatch(setToken(token))
	}
	
	const APPKEY: string = process.env.REACT_APP_APP_KEY || ''

	const [getPassiveNftQuery] = useLazyGetPassiveNftQuery()

	const onPageChange = (newPage: number) => {
		setPage(newPage)
	}

	const getMyWallet = async () => {
		setIsLoading(true)
		try {
			const result = await myWallet({}).unwrap()
			if (result?.statusCode === 200) {
				setWalletData(result?.data || [])
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error)
		}
	}

	useEffect(() => {
		handleSetToken(token)
		getMyWallet()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getDataBaseNft = async () => {
		// let body = {
		//   page: page,
		//   size: 12,
		//   type: value + 1,
		//   sort: sortSelect !== "default" ? sortSelect : "",
		//   sortType:
		//     sortSelect !== "default"
		//       ? positionSelect == "1"
		//         ? "asc"
		//         : "desc"
		//       : "",
		// };

		// console.log(body, "sdjgsgdhsa");
		setIsLoading(true)
		try {
			const result = await getPassiveNftQuery({
				page: page,
				size: 12,
				type: value + 1,
				sort: sortSelect !== 'default' ? sortSelect : '',
				sortType:
					sortSelect !== 'default'
						? positionSelect == '1'
							? 'asc'
							: 'desc'
						: '',
				categoryId:
					categorySelect !== 'default' && categorySelect !== 'All'
						? categorySelect
						: '',
				appkey: APPKEY,
				token: token,
			}).unwrap()
			if (result?.statusCode === 200) {
				setNfts(result?.data?.data || [])
				setTotalCount(result?.data?.count || 0)
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		getDataBaseNft()
	}, [page, value, sortSelect, positionSelect, categorySelect])

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
		setIsListView(false)
		setSortSelect('default')
		setPositionSelect('1')
	}

	return (
		<>
			<Loader isLoad={isLoading} />
			<div className='sec_head'>
				<h3>My Passive Assets </h3>
				<div style={{ marginTop: '3%', marginRight: '5%' }}>
					<p
						style={{ fontWeight: 'bolder' }}
						className='credit_tag'
					>{`Carbon Points : ${
						walletData?.carbonCredits.toLocaleString() || 0
					}`}</p>
					<p
						style={{ fontWeight: 'bolder' }}
						className='credit_tag'
					>{`I2E Points : ${walletData?.I2E_Balance.toLocaleString() || 0}`}</p>
				</div>
			</div>
			<div className='custom_tabs'>
				<Tabs
					value={value}
					onChange={handleChange}
					variant='scrollable'
					scrollButtons='auto'
					aria-label='basic tabs example'
					className='custom_tabs_links'
				>
					<Tab label='Personal' {...a11yProps(0)} />
					<Tab label='Purchased' {...a11yProps(1)} />
				</Tabs>
				<CustomTabPanel value={value} index={0}>
					<SiteFilter
						setPositionSelect={setPositionSelect}
						sortSelect={sortSelect}
						setSortSelect={setSortSelect}
						positionSelect={positionSelect}
						setIsListView={setIsListView}
						isListView={isListView}
						setCategorySelect={setCategorySelect}
						categorySelect={categorySelect}
					/>
					<div
						className={
							!isListView
								? 'subcategory_listing'
								: 'subcategory_listing list_view'
						}
					>
						<div className='gap_m'>
							{nfts?.length
								? nfts?.map((item: NftResponse) => (
										<div
											className={`subcategory_listing_single ${
												item?.couponClaimed ? 'claimed' : ''
											}`}
											onClick={() =>
												navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
											}
										>
											<figure>
												<img
													src={
														item?.image || '/images/explore_attachment_1.jpg'
													}
													alt='Image'
												/>
											</figure>
											<h3>{item?.name || ''}</h3>
											<p>{item?.subCategory?.name || ''}</p>
										</div>
								  ))
								: undefined}

							{nfts?.length ? (
								<SitePagination
									module={nfts}
									page={page}
									onPageChange={onPageChange}
									totalPages={totalPages}
									setPage={setPage}
								/>
							) : undefined}
						</div>
					</div>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<SiteFilter
						setPositionSelect={setPositionSelect}
						sortSelect={sortSelect}
						setSortSelect={setSortSelect}
						positionSelect={positionSelect}
						setIsListView={setIsListView}
						isListView={isListView}
						setCategorySelect={setCategorySelect}
						categorySelect={categorySelect}
					/>
					<div
						className={
							!isListView
								? 'subcategory_listing'
								: 'subcategory_listing list_view'
						}
					>
						<div className='gap_m'>
							{nfts?.length
								? nfts?.map((item: NftResponse) => (
										<div
											className={`subcategory_listing_single ${
												item?.couponClaimed ? 'claimed' : ''
											}`}
											onClick={() =>
												navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
											}
										>
											<figure>
												<img
													src={
														item?.image || '/images/explore_attachment_1.jpg'
													}
													alt='Image'
												/>
											</figure>
											<h3>{item?.name || ''}</h3>
											<p>{item?.subCategory?.name || ''}</p>
										</div>
								  ))
								: undefined}

							{nfts?.length ? (
								<SitePagination
									module={nfts}
									page={page}
									onPageChange={onPageChange}
									totalPages={totalPages}
									setPage={setPage}
								/>
							) : undefined}
						</div>
					</div>
				</CustomTabPanel>
			</div>

			{!isLoading && !nfts?.length ? (
				<NoDataFound image={'/images/noData.png'} text='No Data Found' />
			) : undefined}
		</>
	)
};

export default PassiveNft;
