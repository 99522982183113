import { useEffect, useState } from "react";
import SearchBar from "../../../components/SearchBar";
import { useLazyGetAllNewsQuery } from "../../../services/cms";
import { Blog } from "../../../types/General";
import NewsRecentListing from "../../news/features/newsRecentList";
import NewsListingDetail from "./newsListingDetail";
import { isValidInput } from "../../../utils/validation";

type props = {
  news: Blog | null;
};

const NewsDetailGrid = ({ news }: props) => {
  const [getNews] = useLazyGetAllNewsQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [newsData, setNewsData] = useState<Blog[]>([]);

  const getAllNews = async () => {
    try {
      const res = await getNews({
        search: debouncedSearchTerm.trim(),
        page: 1,
      }).unwrap();
      if (res?.statusCode === 200) {
        setNewsData(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
  }, [debouncedSearchTerm]);

  return (
    <section className="newsGrid_sc u_spc">
      <div className="conta_iner">
        <div className="gap_p">
          <div className="left_s">
            <NewsListingDetail news={news} />
          </div>
          <div className="right_s">
            <aside className="news_aside">
              <div className="single">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => {
                    setSearchTerm("");
                    setDebouncedSearchTerm("");
                  }}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </div>
              <div className="single">
                <NewsRecentListing news={newsData} />
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsDetailGrid;
