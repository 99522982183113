import { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.css';
import { useLocation, useParams } from "react-router-dom";

const MAPBOX_ACCESS_TOKEN= process.env.REACT_APP_MAP_BOX_API_KEY || '';
const URL = process.env.REACT_APP_API_URL || '';

// Check for WebGL support
if (!mapboxgl.supported()) {
    alert('Your browser does not support Mapbox GL');
} else {
    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN || '';
}

const Map = () => {
    const location = useLocation();
    const { id } = useParams();
    const [data, setData] = useState({ user: null, locations: [] });

    useEffect(() => {
        const fetchData = async () => {
            let url;

            // // Check if window.Telegram.WebApp and its nested properties exist
            // if (
            //     window.Telegram &&
            //     window.Telegram.WebApp &&
            //     window.Telegram.WebApp.initDataUnsafe &&
            //     window.Telegram.WebApp.initDataUnsafe.user
            // ) {
            //     console.log("Running in Telegram Web App");
            //     const telegramUserId = window.Telegram.WebApp.initDataUnsafe.user.id;
            //     if (location.pathname === '/collection_centers') {
            //         url = `${URL}/collection_centers`;
            //     }
            //     if (location.pathname === '/') {
            //         url = `${URL}/user?id=${telegramUserId}`;
            //     }
            // } else {
            //     // Default to collection centers URL if not running in Telegram Web App
            //     console.log("Not running in Telegram Web App");
            //     url = `${URL}/collection_centers`;
            // }

            if (location.pathname === '/collection_centers') {
                url = `${URL}/collection_centers`;
            }
            if (location.pathname.startsWith("/user")) {
                const searchParams = new URLSearchParams(location.search);
                const userId = searchParams.get('id');
                const socialMediaType = searchParams.get('social_media_type');
                url = `${URL}/user?id=${userId}&social_media_type=${socialMediaType}`;
            }

            // Only attempt to fetch if a URL has been set
            if (url) {
                try {
                    const response = await fetch(url);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const json = await response.json();
                    setData(json);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, [location, id]);

    useEffect(() => {
        const mapContainer = document.getElementById('map');
        if (mapContainer) {
            // Ensure the map container is empty
            mapContainer.innerHTML = '';

            if (mapboxgl.supported()) {
                const map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [0, 0],
                    zoom: 1,
                });

                const bounds = new mapboxgl.LngLatBounds();

                data.locations.forEach(location => {
                    const locationCoordinates = [...(location.coordinates || location.locations.coordinates)].reverse();

                    const marker = new mapboxgl.Marker()
                        .setLngLat(locationCoordinates)
                        .addTo(map);

                    bounds.extend(locationCoordinates);

                    marker.getElement().addEventListener('click', () => {
                        const destination = marker.getLngLat();
                        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

                        // Check if the device is an iOS device
                        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                            // Use the Apple Maps URL scheme
                            window.open(`maps://?ll=${destination.lat},${destination.lng}&q=Location`);
                        } else {
                            // Use the Geo URI for other devices
                            window.open(`geo:${destination.lat},${destination.lng}?q=${destination.lat},${destination.lng}`);
                        }
                    });
                });

                if (data.user) {
                    const userCoordinates = data.user.coordinates.reverse();
                    new mapboxgl.Marker({
                        color: '#ff0000'
                    })
                        .setLngLat(userCoordinates)
                        .addClassName('user-marker')
                        .addTo(map);

                    bounds.extend(userCoordinates);
                }

                if (data.locations.length > 1 || data.user) {
                    map.fitBounds(bounds, {
                        padding: 150,
                    });
                }
            }
        }
    }, [data]);

    return <div id="map" className="map" />;
};

export default Map;