/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SiteFilter from "../../../components/filter";
import { useLazyGetDbNftQuery } from "../../../services/main/setting";
import { Loader } from "../../../helpers";
import { NftResponse } from "../../../types/General";
import SitePagination from "../../../components/sitePagination";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DatabaseNft = ({
  steps,
  setSteps,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [isListView, setIsListView] = useState(false);
  const [nfts, setNfts] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [sortSelect, setSortSelect] = useState("default");
  const [positionSelect, setPositionSelect] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState("All");
  let totalPages = Math.ceil(totalCount / 12);

  // console.log(positionSelect, ">>>>>>>");

  const [getDbNftQuery] = useLazyGetDbNftQuery();

  const getDataBaseNft = async () => {
    setIsLoading(true);
    try {
      const result = await getDbNftQuery({
        page: page,
        size: 12,
        type: value + 1,
        sort: sortSelect !== "default" ? sortSelect : "",
        sortType:
          sortSelect !== "default"
            ? positionSelect === "1"
              ? "asc"
              : "desc"
            : "",
        categoryId:
          categorySelect !== "default" && categorySelect !== "All"
            ? categorySelect
            : "",
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
        setTotalCount(result?.data?.count || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getDataBaseNft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortSelect, positionSelect, value, categorySelect]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsListView(false);
    setSortSelect("default");
    setPositionSelect("1");
  };

  return (
    <>
      <h3>My Database Assets</h3>
      <Loader isLoad={isLoading} />

      <div className="custom_tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="Approved" {...a11yProps(0)} />
          <Tab label="Unapproved" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <SiteFilter
            categorySelect={categorySelect}
            setCategorySelect={setCategorySelect}
            setPositionSelect={setPositionSelect}
            sortSelect={sortSelect}
            setSortSelect={setSortSelect}
            positionSelect={positionSelect}
            setIsListView={setIsListView}
            isListView={isListView}
          />
          <div
            className={
              !isListView
                ? "subcategory_listing"
                : "subcategory_listing list_view"
            }
          >
            <div className="gap_m">
              {nfts?.length
                ? nfts?.map((item: NftResponse) => (
                    <div
                      className="subcategory_listing_single"
                      onClick={() =>
                        navigate(`/nft-detail/${item?._id}?type=2&path=acc`)
                      }
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/explore_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.categoryId?.name || "By remnynt"}</p>
                    </div>
                  ))
                : undefined}
            </div>
            {/* <div className="btn_flex">
              <button className="btn btn_primary">View More</button>
            </div> */}
            {nfts?.length ? (
              <SitePagination
                module={nfts}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                setPage={setPage}
              />
            ) : undefined}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SiteFilter
            categorySelect={categorySelect}
            setCategorySelect={setCategorySelect}
            setPositionSelect={setPositionSelect}
            sortSelect={sortSelect}
            setSortSelect={setSortSelect}
            positionSelect={positionSelect}
            setIsListView={setIsListView}
            isListView={isListView}
          />
          <div
            className={
              !isListView
                ? "subcategory_listing"
                : "subcategory_listing list_view"
            }
          >
            <div className="gap_m">
              {nfts?.length
                ? nfts?.map((item: NftResponse) => (
                    <div
                      className="subcategory_listing_single"
                      onClick={() =>
                        navigate(`/nft-detail/${item?._id}?type=2&path=acc`)
                      }
                      key={item?._id}
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/explore_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.categoryId?.name || "By remnynt"}</p>
                    </div>
                  ))
                : undefined}
            </div>
            {/* <div className="btn_flex">
              <button className="btn btn_primary">View More</button>
            </div> */}
            {nfts?.length ? (
              <SitePagination
                module={nfts}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                setPage={setPage}
              />
            ) : undefined}
          </div>
        </CustomTabPanel>
      </div>
      {!isLoading && !nfts?.length ? (
        <div className="no_date_sc hd_4">
          <figure>
            <img src="/images/noData.png" alt="noData" />
          </figure>
          <h2>No Data Found</h2>

          <a href="javascript:void(0)" className="btn btn_primary">
            Back to Home
          </a>
        </div>
      ) : undefined}
    </>
  );
};

export default DatabaseNft;
