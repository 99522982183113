import Layout from "../../layout";
import "./corporateSites.scss";
import CorporateSitesHero from "./features/banner";
import HomeNews from "../landingPage/features/news";
import CorporateSitesSection2 from "./features/section2";
import CorporateSitesSection3 from "./features/section3";
import CorporateSitesSection4 from "./features/section4";
import CorporateSitesSection5 from "./features/section5";
import CorporateSitesTrusted from "./features/trusted";

const CorporateSites = () => {

    return (
        <Layout>
            <main className="page_corporateSites">
                <CorporateSitesHero />
                <CorporateSitesSection2 />
                <CorporateSitesSection3 />
                <CorporateSitesSection4 />
                <CorporateSitesSection5 />
                <HomeNews />
                <CorporateSitesTrusted />
            </main>
        </Layout>
    );
};

export default CorporateSites;
