/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { Blog } from "../../../types/General";
import moment from "moment";

type props = {
  news: Blog | null;
};

const NewsListingDetail = ({ news }: props) => {
  const navigate = useNavigate();

  return (
    <div className="newsList_detail">
      <div className="news_single">
        <figure>
          <img src={news?.image || ""} alt="Image" />
        </figure>
        <div className="news_info">
          <span className="date">
            <strong>{moment(news?.createdAt).format("Do")}</strong>{" "}
            {moment(news?.createdAt).format("MMM, YY")}
          </span>
          <span className="views"> {news?.viewCount || 0} Views</span>
        </div>
        <h3>{news?.title || ""}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: news?.description || "",
          }}
        ></p>
      </div>
    </div>
  );
};

export default NewsListingDetail;
