/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const BecomeVendor_Form = () => {
    const navigate = useNavigate();

    return (
        <section className="form_sc uh_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h1>For potential vendors <br /> (impact2earn)</h1>
                    <p>Could you please add your contact details and we will direct you to our Telegram chat for potential vendors of the impact2earn Marketplace.</p>
                    <p>Thank you for your interest in impact2earn.</p>
                    <p>Make Waste not Wasted!</p>
                </div>
                <form action="" className="form">
                    <div className="form_control">
                        <label>NAME *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Name"
                            fullWidth
                            name="name"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>COMPANY *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Company Name"
                            fullWidth
                            name="company"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>CONTACT *</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Contact"
                            fullWidth
                            name="contact"
                            type="number"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>ROLE</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Role"
                            fullWidth
                            name="role"
                            type="text"
                            variant="outlined"
                        />
                    </div>
                    <div className="form_control">
                        <label>NOTES</label>
                        <TextField
                            hiddenLabel
                            placeholder="Enter Note"
                            fullWidth
                            name="name"
                            type="text"
                            variant="outlined"
                            multiline
                            rows={4}
                            sx={{ p: 0 }}
                        />
                    </div>
                    <div className="form_btn">
                        <Button
                            className="btn btn_primary"
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default BecomeVendor_Form;
