/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate, useLocation } from "react-router-dom";
import OnSale_Detail from "./onSale_detail";
import OnBid_Detail from "./onBid_detail";
import Private_Detail from "./private_detail";
import Passive_Detail from "./passive_detail";
import UnapprovedNft_Detail from "./unapprovedNft_Detail";
import Minted_Detail from "./minted_detail";
import { CommonBody, NftResponse } from "../../../types/General";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../../helpers";
import { Box, Modal, TextField } from "@mui/material";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { useUpdateCouponMutation } from "../../../services/main/setting";
import { getToken, setToken } from '../../../reducers/authSlice'
import { useDispatch, useSelector } from 'react-redux'

const NftDetail_Content = ({
  setNfts,
  nfts,
  type,
  getNftByIdData,
  vendors,
  setLoading,
  users,
  similarNfts,
  couponShortNote,
  setCouponShortNote,
  path,
}: {
  setNfts: Dispatch<SetStateAction<NftResponse | undefined>>;
  nfts: NftResponse | undefined;
  type: string | undefined | null;
  getNftByIdData: () => void;
  vendors: any;
  setLoading: Dispatch<SetStateAction<boolean>>;
  users: any;
  similarNfts: NftResponse[] | undefined;
  couponShortNote: string;
  setCouponShortNote: Dispatch<SetStateAction<string>>;
  path: string | null;
}) => {
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)
	const [couponDesc, setCouponDes] = useState('')
	// const [couponShortNote, setCouponShortNote] = useState("");
	console.log('here =======>')
	const handleDeatils = () => {
		switch (Number(type)) {
			case 1:
				return (
					<Passive_Detail
						setNfts={setNfts}
						nfts={nfts}
						getNftByIdData={getNftByIdData}
						vendors={vendors}
						setLoading={setLoading}
						users={users}
						setOpen={setOpen}
					/>
				)
			case 2:
				return <UnapprovedNft_Detail nfts={nfts} />
			case 3:
				return (
					<Minted_Detail
						nfts={nfts}
						getNftByIdData={getNftByIdData}
						setCouponShortNote={setCouponShortNote}
					/>
				)

			default:
				// Add your code block for other steps
				return <OnBid_Detail nfts={nfts} getNftByIdData={getNftByIdData} />
		}
	}
	const [updateCouponMethod] = useUpdateCouponMutation()

	const token = useSelector(getToken)

	const APPKEY: string = process.env.REACT_APP_APP_KEY || '';

	const handleAddDesc = async () => {
		if (!couponDesc) {
			errorToast('Add Coupon Description first')
			return
		}
		let data = {
			couponDescription: couponDesc,
		}
		try {
			const body = generateEncryptedKeyBody(data) as CommonBody

			const result = await updateCouponMethod({
				body,
				claimCouponId: nfts?.claimedCouponId || '',
				token: token,
				appkey: APPKEY,
			}).unwrap()
			if (result?.statusCode === 200) {
				getNftByIdData()
				setCouponDes('')
				successToast('Description added successfully')
				setOpen(false)
			}
		} catch (error: any) {
			errorToast(error?.data?.message || '')
			console.log(error)
		}
	}

	const scrollToTop = () => {
		if (window) {
			window.scrollTo({ top: 0, behavior: 'auto' })
		}
	}

	return (
		<section className='nftDetail_sc ub_spc'>
			<div className='conta_iner'>
				<div className='gap_p'>
					<div className='left_s'>
						<div className='nftDetail_content'>
							<figure>
								<img
									src={nfts?.image || '/images/galleryDummy.jpeg'}
									alt='Image'
								/>
							</figure>
							<div>
								<span className='credit_tag'>{`Carbon Credits : ${
									nfts?.carbonCredit || 0
								}`}</span>
								<div className='hd_3'>
									<span className='credit_tag'>{`Impact 2 Earn Points sda : ${
										nfts?.basePrice || 0
									}`}</span>
								</div>
							</div>

							<div className='nft_desc'>
								<div className='hd_3'>
									<h2>Description</h2>
								</div>
								<p>{nfts?.description || ''}</p>
							</div>
						</div>
					</div>
					<div className='right_s'>
						<div className='p_group'>
							<p className='cat_group'>
								<span
									style={{ fontWeight: 'bold' }}
									// onClick={() => {
									//   navigate(`/explore-subcategory/${nfts?.category?._id}`);
									// }}
								>
									{/* {nfts?.category?.name || ""} */}
									Category:{' '}
								</span>
								<span
									style={{ cursor: 'pointer' }}
									// href="javascript:void()"
									onClick={() => {
										setToStorage(
											STORAGE_KEYS.subCategory,
											nfts?.subCategory ? JSON.stringify(nfts?.subCategory) : ''
										)
										navigate(
											`/explore-subcategory/detail/${nfts?.subCategory?._id}`
										)
									}}
								>
									{nfts?.subCategory?.name || ''}
								</span>
							</p>
						</div>
						<div className='hd_3'>
							<h2>{nfts?.name || 'Nokia'}</h2>
						</div>
						<div className='p_group'>
							<p>
								<b>Brand:</b> {nfts?.brandName || ''}
							</p>
							<p>
								<b>Collection Center:</b> {nfts?.collectioncenters?.name || 0}
							</p>
							{nfts?.audioUrl ? (
								<p className='links'>
									Audio Clip:{' '}
									<span
										onClick={() =>
											window.open(
												nfts?.audioUrl?.includes('"https://"')
													? nfts?.audioUrl
													: 'https://' + nfts?.audioUrl,
												'_blank'
											)
										}
									>
										{nfts?.audioUrl || ''}
									</span>
								</p>
							) : undefined}
						</div>
						<div className='nft_desc'>
							<div className='hd_3'>
								<h2>Description</h2>
							</div>
							<h3>{nfts?.description ? '' : 'By 170B84'}</h3>
							<p>
								{nfts?.description ||
									'From the hard, bitter ground of the Phestia mountains, the people scraped and scratched at survival for generations. Until she emerged. Bursting forth from the white hot center of the Earth, Domina Ignis rose, bathed in flame and pulsing like magma. In her shining arms she brought fire, chaos, and death. But when the smoke had cleared and the dust had settled, life had sprung from the fertilized rock. A city was built around the blaze, and the people learned to revere and trust Domina Ignis and her powerful gifts. With each eruption, they learned to weather her wrath with cunning and ingenuity, waiting for the harvest that followed her blazing rain. But the problem with aligning with the Lady of Fiery Temper: eventually everyone burns.'}
							</p>
						</div>
						{handleDeatils()}
						{/* <OnSale_Detail /> */}
						{/* <OnBid_Detail /> */}
						{/* <Private_Detail /> */}
						{/* <Passive_Detail nfts={nfts} /> */}
						{/* <UnapprovedNft_Detail nfts={nfts} /> */}
						{/* <Minted_Detail /> */}
					</div>
				</div>

				<div className='similar_sc uht_spc'>
					{similarNfts?.length ? (
						<div className='hd_3'>
							<h2>Similar Assets</h2>
						</div>
					) : undefined}
					<div className='subcategory_listing'>
						<div className='gap_m'>
							{similarNfts?.length
								? similarNfts?.map(item => (
										<div
											className='subcategory_listing_single'
											key={item?._id}
											onClick={() => {
												scrollToTop()
												navigate(
													`/nft-detail/${item?._id}${type ? '?type=1' : ''}${
														path && type ? `&path=${path}` : ''
													}${path && !type ? `?path=${path}` : ''}`,
													{ replace: true }
												)
											}}
										>
											<figure>
												<img
													src={item?.image || '/images/nft_attachment_1.jpg'}
													alt='Image'
												/>
											</figure>
											<h3>{item?.name || ''}</h3>
											{/* <p>By remnynt</p> */}
										</div>
								  ))
								: undefined}
						</div>
					</div>
				</div>
			</div>
			{/* <Modal
        open={open}
        // onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_modal"
      > */}
			{/* <Box className="modal_body"> */}
			{/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography> */}
			{/* <div className="modal_content">
            <div className="short_dsc">
              <h2 className="modal_title text_left">Please Provide Input</h2>
              <p className="desc_txt">{nfts?.coupon?.description || ""}</p>
            </div> */}

			{/* <h2 className="modal_title text_left">Add Coupon Description</h2> */}

			{/* <div className="form_control">
              <TextField
                multiline
                maxRows={4}
                minRows={4}
                hiddenLabel
                fullWidth
                id="email"
                name="email"
                type="email"
                variant="outlined"
                className="text_field"
                placeholder="Description"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    setCouponDes(val.target.value);
                  }
                }}
                value={couponDesc}
              />
            </div>
            <div className="btn_flex">
              <button onClick={handleAddDesc} className="btn btn_primary">
                Submit
              </button>
              <button onClick={() => setOpen(false)} className="btn_link">
                I Will do it later
              </button>
            </div>
          </div> */}
			{/* </Box>
      </Modal> */}
		</section>
	)
};

export default NftDetail_Content;
