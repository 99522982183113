/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import BlogsListing from "./features/blogsListing";

const Blogs = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <main className="page_blogs">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>Blogs</li>
            </ul>
          </div>
        </section>

        <BlogsListing />
      </main>
    </Layout>
  );
};

export default Blogs;
