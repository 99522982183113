/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SiteFilter from "../../../components/filter";
import { NftResponse } from "../../../types/General";
import SitePagination from "../../../components/sitePagination";
import NoDataFound from "../../../components/noData";
import useAuth from "../../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlatformNFTs_Content = ({
  nfts,
  page,
  setPage,
  totalPages,
  searchTerm,
  setDebouncedSearchTerm,
  setSearchTerm,
  loading,
  totalCount,
  sortSelect,
  setSortSelect,
  positionSelect,
  setPositionSelect,
  setCategorySelect,
  categorySelect,
  setValue,
  value,
}: {
  nfts: NftResponse[] | undefined;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  searchTerm?: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  loading: boolean;
  totalCount: number;
  sortSelect: string;
  setSortSelect: Dispatch<SetStateAction<string>>;
  positionSelect: any;
  setPositionSelect: Dispatch<SetStateAction<any>>;
  setCategorySelect: Dispatch<SetStateAction<string>>;
  categorySelect: string;
  setValue: Dispatch<SetStateAction<number>>;
  value: number;
}) => {
  const navigate = useNavigate();
  const user = useAuth();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [isListView, setIsListView] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSortSelect("default");
    setPositionSelect("1");
    setCategorySelect("All");
  };

  return (
    <section className="ec_category_sc ub_spc">
      <div className="conta_iner">
        <div className="s_head f_width s_head_flex">
          <h1>Platform Asset’s</h1>
          <div className="right_s">
            <a
              href="javascript:void(0)"
              className="btn btn_primary"
              onClick={() => {
                navigate("/explore-subcategory/list");
              }}
              // onClick={() => navigate("/explore-category")}
            >
              Explore Category
            </a>
          </div>
        </div>
        <div className="custom_tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Passive" {...a11yProps(0)} />
            <Tab
              // className="clickDisable"
              // disabled
              label="Minted"
              {...a11yProps(1)}
            />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <SiteFilter
              setCategorySelect={setCategorySelect}
              categorySelect={categorySelect}
              setPositionSelect={setPositionSelect}
              sortSelect={sortSelect}
              setSortSelect={setSortSelect}
              positionSelect={positionSelect}
              setIsListView={setIsListView}
              isListView={isListView}
              type="explore"
            />
            <div className="subcategory_listing">
              <div className="gap_m">
                {nfts?.length
                  ? nfts?.map((item: NftResponse) => (
                      <div
                        className={`subcategory_listing_single ${
                          item?.couponClaimed ? "claimed" : ""
                        }`}
                        onClick={() => {
                          if (user?._id === item?.userId) {
                            navigate(
                              `/nft-detail/${item?._id}?type=1&path=platform`
                            );
                          } else {
                            navigate(`/nft-detail/${item?._id}?path=platform`);
                          }
                        }}
                      >
                        <figure>
                          <img
                            src={
                              item?.image || "/images/explore_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <h3>{item?.name || ""}</h3>

                        <p>
                          {item?.subCategoryId?.name
                            ? item?.subCategoryId?.name
                            : ""}
                        </p>
                        <span className="tag c_primary">
                          {item?.sellType === 1 ? "On Bid" : "Private"}
                        </span>
                      </div>
                    ))
                  : undefined}
              </div>
              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SiteFilter
              setCategorySelect={setCategorySelect}
              categorySelect={categorySelect}
              setPositionSelect={setPositionSelect}
              sortSelect={sortSelect}
              setSortSelect={setSortSelect}
              positionSelect={positionSelect}
              setIsListView={setIsListView}
              isListView={isListView}
              type="explore"
            />
            <div className="subcategory_listing">
              <div className="gap_m">
                {nfts?.length
                  ? nfts?.map((item: NftResponse) => (
                      <div
                        className={`subcategory_listing_single ${
                          item?.couponClaimed ? "claimed" : ""
                        }`}
                        onClick={
                          () => {
                            if (user?._id === item?.userId) {
                              navigate(
                                `/nft-detail/${item?._id}?type=1&path=platform`
                              );
                            } else {
                              navigate(
                                `/nft-detail/${item?._id}?path=platform`
                              );
                            }
                          }
                          // navigate(`/nft-detail/${item?._id}?path=platform`)
                        }
                      >
                        <figure>
                          <img
                            src={
                              item?.image || "/images/explore_attachment_1.jpg"
                            }
                            alt="Image"
                          />
                        </figure>
                        <h3>{item?.name || ""}</h3>
                        <p>{item?.subCategoryId?.name || ""}</p>
                        <span className="tag c_primary">
                          {item?.sellType === 1 ? "On Bid" : "Private"}
                        </span>
                      </div>
                    ))
                  : undefined}
              </div>
              {nfts?.length ? (
                <SitePagination
                  module={nfts}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : undefined}
            </div>
          </CustomTabPanel>
        </div>
      </div>
      {!loading && !nfts?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </section>
  );
};

export default PlatformNFTs_Content;
