/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { useLazyGetAllBlogQuery } from "../../../services/cms";
import { useEffect, useState } from "react";
import { Blog } from "../../../types/General";
import moment from "moment";

const HomeBlogs = () => {
  const navigate = useNavigate();
  const [getBlog] = useLazyGetAllBlogQuery();

  const [blogs, setBlogs] = useState<Blog[]>([]);

  const getAllBlogs = async () => {
    try {
      const res = await getBlog({}).unwrap();
      if (res?.statusCode === 200) {
        setBlogs(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      {blogs?.length ? (
        <section className="home_blogs_sc u_spc">
          <div className="conta_iner">
            <div className="s_head text_center">
              <h2>Latest Blogs</h2>
            </div>
            <div className="blog_listing">
              <div className="gap_m hd_6">
                {blogs?.length
                  ? blogs?.slice(0, 3)?.map((item) => (
                      <div
                        className="blog_single"
                        onClick={() => navigate(`/blogs/detail/${item?._id}`)}
                      >
                        <figure>
                          <img src={item?.image || ""} alt="Image" />
                        </figure>
                        <span className="tag">Blog</span>
                        <h3>{item?.title || ""}</h3>
                        <p> {moment(item?.createdAt).format("MMM D, YYYY")}</p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            {blogs?.length ? (
              <div className="btn_flex">
                <button
                  onClick={() => navigate("/blogs")}
                  className="btn btn_lg btn_primary"
                >
                  See All
                </button>
              </div>
            ) : undefined}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default HomeBlogs;
