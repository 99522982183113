/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import NewsDetailBanner from "./features/newsDetailBanner";
import NewsDetailGrid from "./features/newsDetailGrid";
import { useEffect, useState } from "react";
import { useLazyGetNewsByIdQuery } from "../../services/cms";
import { Blog } from "../../types/General";
import { errorToast } from "../../helpers";

const NewsDetail = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [newsById, { isLoading }] = useLazyGetNewsByIdQuery();

  const [news, setNews] = useState<Blog | null>(null);

  const getNewsByById = async (id: string) => {
    try {
      const response = await newsById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setNews(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getNewsByById(id);
    }
  }, [id]);

  return (
    <Layout>
      <main className="page_newsDetail">
        <NewsDetailBanner news={news} />
        <NewsDetailGrid news={news} />
      </main>
    </Layout>
  );
};

export default NewsDetail;
