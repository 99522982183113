/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import "./exploreSubcategory.scss";
import ExploreSubcategory_Subcategory from "./features/subCategory";
import { useLazyGetSubCategoryQuery } from "../../services/main/category";
import { Loader } from "../../helpers";

const ExploreSubcategory = () => {
  const navigate = useNavigate();
  const [getSubCategoryMethod] = useLazyGetSubCategoryQuery();

  const [subCategories, setSubCategories] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  let totalPages = Math.ceil(totalCount / 12);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const { catId } = useParams();

  const getCategories = async () => {
    try {
      setLoading(true);
      const result = await getSubCategoryMethod({
        catId: catId,
        page: page,
        size: 10,
        search: debouncedSearchTerm || "",
        pagination: true,
      }).unwrap();
      setLoading(false);
      if (result?.statusCode === 200) {
        setSubCategories(result?.data?.category || []);
        setTotalCount(result?.data?.count || 0);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (catId) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catId, page, debouncedSearchTerm]);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <main className="page_explore">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                {/* eslint-disable-next-line no-script-url */}
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>Explore</li>
            </ul>
          </div>
        </section>

        <ExploreSubcategory_Subcategory
          subCategories={subCategories}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          loading={loading}
        />
      </main>
    </Layout>
  );
};

export default ExploreSubcategory;
