/* eslint-disable jsx-a11y/img-redundant-alt */
import { Dispatch, SetStateAction } from "react";
import NoDataFound from "../../../components/noData";
import { SOCIAL_MEDIA_TYPE } from "../../../utils/enums";
import EditIcon from "@mui/icons-material/Edit";
import { useUpdateSocialMediaMutation } from "../../../services/main/setting";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import DeleteIcon from "@mui/icons-material/Delete";

type ManageProps = {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
  setSocialMediaHandles: Dispatch<SetStateAction<number>>;
  setSocialMedia: any;
  socialMedia: any;
  setSelectedMedia: Dispatch<SetStateAction<any>>;
  getSocialMediaData: () => void;
};

const ManageSocialMedia = ({
  steps,
  setSteps,
  setSocialMediaHandles,
  setSocialMedia,
  socialMedia,
  setSelectedMedia,
  getSocialMediaData,
}: ManageProps) => {
  const [updateSocialMediaMethod] = useUpdateSocialMediaMutation();

  const handleChangeStatus = async (item: any) => {
    try {
      let data = {
        isDeleted: true,
      };
      const body = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateSocialMediaMethod({
        body,
        socialMediaId: item?._id,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast("Deleted Successfully");
        getSocialMediaData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  return (
    <>
      <div className="head_flex">
        <h3>Manage Social Media</h3>
        <button
          onClick={() => {
            setSocialMediaHandles(2);
            setSelectedMedia("");
          }}
          className="btn btn_primary"
        >
          Add
        </button>
      </div>
      <div className="socialMedia_list">
        {socialMedia?.length ? (
          socialMedia?.map((item: any) => (
            <div key={item?._id} className="single">
              {/* <Switch
                {...label}
                checked={!item?.isBlocked}
                onChange={() => handleChangeStatus(item)}
              /> */}

              <button
                onClick={() => handleChangeStatus(item)}
                className="action_btn2"
                type="button"
              >
                <DeleteIcon />
              </button>

              <button
                onClick={() => {
                  setSelectedMedia(item);
                  setSocialMediaHandles(item?._id);
                }}
                className="action_btn"
                type="button"
              >
                <EditIcon />
              </button>

              <ul>
                <li>
                  <p>SOCIAL PLATFORM</p>
                  <p>
                    {item?.socialMediaType
                      ? SOCIAL_MEDIA_TYPE[item?.socialMediaType]
                      : ""}
                  </p>
                </li>
                <li>
                  <p>SOCIAL ID</p>
                  <p>{item?.socialMediaId || ""}</p>
                </li>
                {item?.phone ? (
                  <li>
                    <p>NUMBER LINKED WITH ACCOUNT </p>
                    <p>{`${item?.countryCode || ""} ${item?.phone || ""}`}</p>
                  </li>
                ) : undefined}
                {item?.description ? (
                  <li>
                    <p>DESCRIPTION </p>
                    <p>{item?.description || ""}</p>
                  </li>
                ) : undefined}
              </ul>
            </div>
          ))
        ) : (
          <NoDataFound image={"/images/noData.png"} text="No Data Found" />
        )}
      </div>
    </>
  );
};

export default ManageSocialMedia;
