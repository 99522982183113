import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { getCms } from "../../reducers/generalSlice";
import "./cms.scss";

const Disclaimer = () => {
  const navigate = useNavigate();
  const getCmsData = useAppSelector(getCms);

  return (
    <Layout>
      <main className="page_cms">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>Disclaimer</li>
            </ul>
          </div>
        </section>

        <section className="cms_content uhb_spc">
          <div className="conta_iner">
            <div
              className={getCmsData ? "policy_txt" : "policy_txt2"}
              dangerouslySetInnerHTML={{
                __html: getCmsData ? getCmsData?.disclaimer : "No Data Found",
              }}
            />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Disclaimer;
