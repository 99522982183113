export const API_URL: string = process.env.REACT_APP_MARKETPLACE_API_URL || '';


export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  getProfile: "getProfile",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  mediaUpload: "upload",
  logout: "logout",
  update_profile: "profile",
  get_user: "getProfile",
  register: "register",
  signup: "signup",

  // category
  getCategory: "subCategory",
  getSubCat: "subCategory",

  // cms
  getCms: "cms",
  getFaq: "faq",
  support: "support",

  // accountSCreen
  dbNft: "myDatabaseNft",
  convertToPassive: "convertToPassive",
  passiveNft: "myPassiveNft",
  nftById: "nftById",
  setOnBid: "setOnBid",
  myNftOnBid: "myNftOnBid",
  acceptBidWinner: "acceptBidWinner",
  giftNft: "giftNft",
  getVendorsList: "getB2bList",
  claimCoupon: "claimCoupon",
  myGiftNft: "myGiftNft",
  getUsers: "getUsers",
  myClaimedCoupon: "myClaimedCoupon",
  activities: "notification",
  updateCoupon: "updateCoupon",
  myWallet: "myWallet",
  mintMyNft: "mintMyNft",
  myMintedNft: "myMintedNft",
  getOrganizationCoupon: "getOrganizationCoupon",

  // socialMedia
  socialMedia: "socialMedia",

  // explore
  nftBySubCategoryId: "nftBySubCategoryId",
  bidOnNft: "bidOnNft",
  getSimilarNfts: "relatedNftBySubCategoryId",
  allNfts: "allNfts",

  // home
  getBanners: "getBanner",

  getBlog: "getBlog",
  getTestimonial: "getTestimonial",
  getNews: "getNews",
  getOrganisation: "organisation",
  newsLetter: "subscribeNewsletter",
};

export const becomeVendorLink: string =
  "https://noteforms.com/forms/for-potential-vendors-sl4sjq";
export const becomePartnerLink: string =
  "https://noteforms.com/forms/for-potential-partners-bantgo-qixyq5";
export const jobOpportunityLink: string =
  "https://noteforms.com/forms/candidates-2-bantgo-sia9yp";