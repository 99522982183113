import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../app/store'
import type { User } from '../types/User'
import { removeFromStorage, STORAGE_KEYS } from '../helpers'

export interface AuthState {
	user: User | null
	token: any
	tempToken: string | null
	walletAddress: string | null
}

const initialState: AuthState = {
	user: null,
	token: null,
	tempToken: null,
	walletAddress: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAuth: state => {
			removeFromStorage(STORAGE_KEYS.token)
			state.user = null
			state.token = null
		},
		setCredentials: (
			state,
			action: PayloadAction<Pick<AuthState, 'user' | 'token'>>
		) => {
			state.user = action.payload.user
			state.token = action.payload.token
		},
		temporaryToken: (
			state,
			action: PayloadAction<Pick<AuthState, 'tempToken'>>
		) => {
			state.tempToken = action.payload.tempToken
		},
		setToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload
		},
		metaMaskAddress: (
			state,
			action: PayloadAction<Pick<AuthState, 'walletAddress'>>
		) => {
			state.walletAddress = action.payload.walletAddress
		},
	},
})

export const {
	resetAuth,
	setCredentials,
	temporaryToken,
	metaMaskAddress,
	setToken,
} = authSlice.actions

export const getCurrentUser = (state: RootState) => state.auth.user
export const getToken = (state: RootState) => state.auth.token
export const getMetaMaskAmount = (state: RootState) => state.auth.walletAddress

export default authSlice.reducer
