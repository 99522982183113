import React from "react";
import "./home.scss";
import Layout from "../../layout";
import HomeHero from "./features/banner";
import HomeSteps from "./features/steps";
import HomeLearn from "./features/learn";
import HomeExplore from "./features/explore";
import HomePartner from "./features/partner";
import HomeVendors from "./features/vendor";
import HomeTestimonial from "./features/testimonial";
import HomeBlogs from "./features/blogs";
import JoinMovement from "./features/join";
import HomeNews from "./features/news";
import { useNavigate } from "react-router-dom";
import userAuth from '../../hooks/useAuth'
import YourHomeExplore from "./features/yourNFTExplore";

const LandingPage = () => {
  const navigate = useNavigate();
  const user = userAuth();
  return (
    <Layout>
      <main className="page_home">
        <HomeHero />
        <HomeSteps />
        {user && <YourHomeExplore/>}
        <HomeLearn />
        <HomeExplore />
        <HomePartner type="" />

        <HomeVendors type="" />

        <HomeTestimonial />
        <HomeBlogs />
        <JoinMovement />
        <HomeNews />
      </main>
    </Layout>
  );
};

export default LandingPage;
