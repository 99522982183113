/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetBlogByIdQuery } from "../../../services/cms";
import { Loader, errorToast } from "../../../helpers";
import { Blog } from "../../../types/General";
import { useEffect, useState } from "react";
import moment from "moment";

const BlogDetailContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blogById, { isLoading }] = useLazyGetBlogByIdQuery();

  const [blog, setBlog] = useState<Blog | null>(null);

  const getBlogByById = async (id: string) => {
    try {
      const response = await blogById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setBlog(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getBlogByById(id);
    }
  }, []);

  return (
    <section className="blogDetail_sc ub_spc">
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="inner">
          <figure className="blog_figure">
            <img src={blog?.image || ""} alt="Image" />
          </figure>
          <span className="tag">Blog</span>
          <div className="hd_3">
            <h1>{blog?.title || ""}</h1>
          </div>
          <p>{moment(blog?.createdAt).format("MMM D, YYYY")}</p>
          <div className="blog_desc hd_5">
            <p
              dangerouslySetInnerHTML={{
                __html: blog?.description ? blog?.description : "",
              }}
            ></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailContent;
