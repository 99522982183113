import { AuthCommon } from "../../../../components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import OtpInput from "react-otp-input";
import FormControl from "@mui/material/FormControl";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../../services/auth";
import {
  Loader,
  errorToast,
  successToast,
  setToStorage,
  STORAGE_KEYS,
} from "../../../../helpers";
import { setCredentials, temporaryToken } from "../../../../reducers/authSlice";
import { CommonBody } from "../../../../types/General";
import { useAppDispatch } from "../../../../hooks/store";

const otpStyle = {
  width: "60px",
  height: "60px",
  "box-sizing": "border-box",
  margin: "0 10px 0 0",
  "font-size": "25px",
  "font-weight": "700",
};

const OtpContent = () => {
  const location = useLocation();
  const { state } = location;
  console.log("state: ", state);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);

  const [optVerificationMutation, { isLoading }] = usePostVerifyOtpMutation();
  const [ForgotPasswordMutation, ForgotPasswordData] =
    usePostForgotPasswordMutation();

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const body = {
        key: state?.auth ? state?.key : state?.email,
        code: otp,
      };

      if (state?.auth) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await optVerificationMutation(
            encryptedBody
          ).unwrap();
          if (response?.statusCode === 200) {
            setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
            dispatch(
              setCredentials({
                user: response?.data,
                token: response?.data?.token || "",
              })
            );
            if (response?.data?.isProfileComplete) {
              navigate("/");
            } else {
              navigate("/profile-setup");
            }
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await optVerificationMutation(
            encryptedBody
          ).unwrap();
          if (response?.statusCode === 200) {
            dispatch(temporaryToken({ tempToken: response?.data?.token }));

            navigate("/reset-password", { replace: true });
          }
        } catch (error: any) {
          if (error?.data?.message) {
            errorToast(error?.data?.message || "");
          }
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    // setCountDown(59);
    // return;
    let body = {
      email: state?.auth ? state?.key : state?.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await ForgotPasswordMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(
          `OTP Resend Successfully, if not received check spam email`
        );
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon />
            <div className="right_side">
              <div className="form hd_3">
                <h2>Verify Account</h2>
                <div className="form_control">
                  <label>
                    Please enter the verification code we sent to your email
                    address
                  </label>
                  <FormControl className="opt_fields" sx={{ width: "100%" }}>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      inputStyle={otpStyle}
                      inputType="tel"
                    />
                    {error && otp.length !== 4 ? (
                      <h6 className="err_msg" style={{ paddingTop: 10 }}>
                        This field is required
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
                <div className="form_bottom">
                  {countDown === 0 ? (
                    <p>
                      Didn't get the code?{" "}
                      <a 
                      // href="javacript:void(0)" 
                      onClick={handleResendOtp}>
                        Resend
                      </a>
                    </p>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        minWidth: 70,
                      }}
                    >
                      The verification code will expire in{" "}
                      <a 
                      // href="javacript:void(0)" 
                      onClick={() => {}}>
                        {countDown < 10
                          ? `00: 0${countDown}`
                          : `00: ${countDown}`}
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpContent;
