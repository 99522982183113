/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useContactUsFormMutation } from "../../../services/cms";
import { useState } from "react";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isString } from "../../../utils/validation";

const Contact_Form = () => {
  const navigate = useNavigate();

  const [contactUsFormMethod] = useContactUsFormMutation();

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      firstName: "",
      phone: "",
      countryCode: "+971",
      companyName: "",
      message: "",
      accept: false,
    },
    validationSchema: Yup.object({
      accept: Yup.boolean(),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      companyName: Yup.string()
        .required("Company Name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      message: Yup.string()
        .required("Message is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      if (!formik.values.accept) {
        errorToast("Please accept privacy policy to proceed further");
        return;
      }
      let body = {
        name: formik.values.firstName,
        email: formik.values.email,
        countryCode: formik.values.countryCode,
        phone: formik.values.phone,
        companyName: formik.values.companyName,
        query: formik.values.message,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await contactUsFormMethod(encryptedBody).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          successToast("Form Submitted successfully");
          navigate("/", { replace: true });
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <section className="form_sc uhb_spc">
      <div className="conta_iner">
        <form onSubmit={formik.handleSubmit}>
          <div className="form form_control">
            <div className="form_control">
              <label>Name *</label>
              <TextField
                hiddenLabel
                placeholder="First Name"
                fullWidth
                type="text"
                variant="outlined"
                name="firstName"
                className="text_field"
                id="firstName"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="form_control">
              <label>Email *</label>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Email"
                fullWidth
                type="email"
                variant="outlined"
                name="email"
                id="email"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>

            <div className="form_control">
              <label className="custom_label">Phone Number</label>
              <PhoneInput
                value={formik.values.countryCode + formik.values.phone}
                country={"us"}
                placeholder="0 (000) 000-000"
                enableSearch={true}
                inputStyle={{ width: "100%" }}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <h6 className="err_msg">
                  {formik.touched.phone && formik.errors.phone}
                </h6>
              ) : (
                ""
              )}
            </div>

            <div className="form_control">
              <label>Company Name</label>
              <TextField
                hiddenLabel
                placeholder="Company Name"
                fullWidth
                name="companyName"
                type="text"
                variant="outlined"
                className="text_field"
                id="companyName"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </div>
            <div className="form_control">
              <label>Message</label>

              <TextField
                hiddenLabel
                placeholder="Enter Message"
                fullWidth
                name="message"
                type="text"
                variant="outlined"
                className="text_field"
                multiline
                rows={4}
                sx={{ p: 0 }}
                id="message"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                helperText={formik.touched.message && formik.errors.message}
              />
            </div>
          </div>
          <div className="form_control">
            {/* <p className="f_med">
              By clicking 'submit' below, you consent to allow BANTgo Inc to
              store and process the personal information submitted above to
              provide you the content requested.
            </p> */}
            <div className="remember_box">
              <FormControlLabel
                control={<Checkbox />}
                label=""
                className="checkbox_label"
                checked={formik.values.accept}
                name="accept"
                onChange={formik.handleChange}
              />

              <p className="text_lab v2">
                By clicking 'submit' below, I agree to process and store my data
                under BANTgo’s{" "}
                <a
                  // href="javacript:void(0)"
                  style={{cursor:'pointer'}}
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </a>{" "}
                to provide me the content requested.
              </p>
            </div>
          </div>
          <div className="form_btn">
            <Button
              className="btn btn_primary"
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact_Form;
