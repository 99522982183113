import Layout from "../../../layout";
import ProfileSetupContent from "./features/profileSetup";
import "../../auth/auth.scss";

const ProfileSetup = () => {
  return (
    <Layout>
      <main>
        <ProfileSetupContent />
      </main>
    </Layout>
  );
};

export default ProfileSetup;
