/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { useNavigate } from "react-router-dom";
import BlogDetailContent from "./features/blogDetailContent";
import "./blogDetail.scss";

const BlogDetail = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <main className="page_blogDetail">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/blogs")}>
                  Blogs
                </a>
              </li>
              <li>Blog Detail</li>
            </ul>
          </div>
        </section>

        <BlogDetailContent />
      </main>
    </Layout>
  );
};

export default BlogDetail;
