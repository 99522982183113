/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";

type newsListProps = {
  news: any;
};

const NewsRecentListing = ({ news }: newsListProps) => {
  // console.log(news);
  const navigate = useNavigate();

  return (
    <div className="newsRecent_listing">
      {news?.length
        ? news?.map((item: any) => (
            <div
              className="newsRecent_single"
              onClick={() => navigate(`/news/detail/${item?._id}`)}
            >
              <figure>
                <img src={item?.image} alt="Image" />
              </figure>
              <div className="newsRecent_info">
                <span className="date">
                  <CalendarMonthIcon />
                  {moment(item?.createdAt).format("MMM DD, YYYY")}
                </span>
                <h3>{item?.title}</h3>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

export default NewsRecentListing;
