import { useNavigate } from "react-router-dom";

const CorporateSitesSection3 = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_sc3 bg_primary u_spc">
            <div className="conta_iner">
                <div className="gap_p">
                    <div className="left_s">
                        <div className="s_hd text_white hd_3">
                            <h2>Viewing garbage as something foul and unwanted – this is an antiquated attitude</h2>
                            <p>Our objective is not only to change the negative perception of waste but to revolutionize the way it is managed.</p>
                            <p>Currently, we generate roughly 40 million tons of electronic waste (e-waste) per year with 85% destined for landfills and incinerators which contribute to 70% of our overall toxic waste.</p>
                            <p>By exchanging the antiquated process of disposing of unsorted waste in landfills for the adoption of a closed-loop system of proper sorting, recycling, and repurposing, we can revolutionize recycling for both consumers and businesses.</p>
                            <p>By embracing this approach, we can create a world where waste is no longer seen as a burden, but as a valuable resource that can be harnessed to build a more sustainable future.</p>
                        </div>
                    </div>
                    <div className="right_s">
                        <figure><img src="/images/broken-screen-mobile.png" alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorporateSitesSection3;
