/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { Blog } from "../../../types/General";

type props = {
  news: Blog | null;
};

const NewsDetailBanner = ({ news }: props) => {
  const navigate = useNavigate();

  return (
    <section className="newsBanner_sc">
      <div className="conta_iner">
        <div className="s_hd hd_2 text_white">
          <h1>{news?.title || ""}</h1>
          <div className="site_pagination">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/news")}>
                  Bantgo-news
                </a>
              </li>
              <li>{news?.title || ""}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsDetailBanner;
